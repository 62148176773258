/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'navigation-features',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/dashboard',
  },
  {
    id: 'dashboard.purchase-order',
    title: 'Events',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/sourcing',
  },
  {
    id: 'dashboard.purchase-order',
    title: 'Purchase Order',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/po',
  },
  {
    id: 'dashboard.goods-receipt-advise',
    title: 'Goods Receipt Advice',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/gra',
  },
  {
    id: 'dashboard.invoice',
    title: 'Invoice',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/invoice',
  },
  {
    id: 'dashboard.payment-remittance',
    title: 'Payment Remittance',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/payment-remittance-supplier',
  },
  {
    id: 'dashboard.supplier-contract-price',
    title: 'Contract Price',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/supplier-contract-list',
  },
  // {
  //     id: 'dashboard.pir',
  //     title: 'Purchase Info Records (PIR)',
  //     type: 'basic',
  //     icon: 'heroicons_outline:chart-pie',
  //     link: '/pir',
  // },

  {
      id: 'dashboard.pir',
      title: 'Supplier Form',
      type: 'basic',
      icon: 'heroicons_outline:chart-pie',
      link: '/supplier-form',
  },
  {
      id: 'dashboard.pir',
      title: 'User List',
      type: 'basic',
      icon: 'heroicons_outline:chart-pie',
      link: '/supplier-user',
  },
  {
    id: 'dashboard.pir',
    title: 'User Guide',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/user-guide',
  },
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboards',
    tooltip: 'Dashboards',
    type: 'aside',
    icon: 'heroicons_outline:home',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'apps',
    title: 'Apps',
    tooltip: 'Apps',
    type: 'aside',
    icon: 'heroicons_outline:qrcode',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'pages',
    title: 'Pages',
    tooltip: 'Pages',
    type: 'aside',
    icon: 'heroicons_outline:document-duplicate',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'user-interface',
    title: 'UI',
    tooltip: 'UI',
    type: 'aside',
    icon: 'heroicons_outline:collection',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'navigation-features',
    title: 'Navigation',
    tooltip: 'Navigation',
    type: 'aside',
    icon: 'heroicons_outline:menu',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'DASHBOARDS',
    type: 'group',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'apps',
    title: 'APPS',
    type: 'group',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'others',
    title: 'OTHERS',
    type: 'group',
  },
  {
    id: 'pages',
    title: 'Pages',
    type: 'aside',
    icon: 'heroicons_outline:document-duplicate',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'user-interface',
    title: 'User Interface',
    type: 'aside',
    icon: 'heroicons_outline:collection',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'navigation-features',
    title: 'Navigation Features',
    type: 'aside',
    icon: 'heroicons_outline:menu',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboards',
    type: 'group',
    icon: 'heroicons_outline:home',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'apps',
    title: 'Apps',
    type: 'group',
    icon: 'heroicons_outline:qrcode',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'pages',
    title: 'Pages',
    type: 'group',
    icon: 'heroicons_outline:document-duplicate',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'user-interface',
    title: 'UI',
    type: 'group',
    icon: 'heroicons_outline:collection',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'navigation-features',
    title: 'Misc',
    type: 'group',
    icon: 'heroicons_outline:menu',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];

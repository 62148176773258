import { INPUT_FIELD_TYPE } from './../../../../../enums/form-enum/form-enum';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Question } from 'app/interfaces/rfx-interface/questionnaire.interface';
import { generateArrayFromEnum } from 'app/helper/shared-function';
import { ANSWER_TYPE } from 'app/enums/rfx-enums/rfx.enum';

const evaluationMappingList = [
    { name: 'quality / specification', validation: null },
    { name: 'lead time', validation: 'required' },
    { name: 'coast', validation: null },
    { name: 'warranty', validation: 'required' }
]

interface QuestionMatData {
    formValue: Question
    submitForm: Function,
}

@Component({
    selector: 'app-qestion-form',
    templateUrl: './qestion-form.component.html',
    styleUrls: ['./qestion-form.component.scss']
})

export class QestionFormComponent implements OnInit {
    questionForm: FormGroup
    answerTypeList = generateArrayFromEnum(ANSWER_TYPE)

    evaluationList = evaluationMappingList
    ANSWER_TYPE_ENUM = ANSWER_TYPE
    INPUT_FIELD_TYPE = INPUT_FIELD_TYPE
    selectedAnswerType: string // selected value form answer type dropdown

    extractedAnswers: string[] = [ANSWER_TYPE.CHOICE, ANSWER_TYPE.CHECKBOX]

    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private questionData: QuestionMatData
    ) { }

    ngOnInit(): void {
        this.initQuestionForm()
        this.patchQuesitonForm()
    }

    initQuestionForm() {
        this.questionForm = this.fb.group({
            id: null,
            question: ['', [Validators.required, Validators.maxLength(250)]],
            answerType: ['', Validators.required],
            evaluationMapping: '',
            isRequired: false,
            canSupplierAttachDocument: false,
            isAttachmentRequired: false,
        })
    }

    choiceWithScoreForm(): FormGroup {
        return this.fb.group({
            id: null,
            score: [''],
            value: ['']
        })
    }

    patchQuesitonForm() {
        const questionInfo: Question = this.questionData.formValue
        if (!questionInfo) return        
        
        this.questionForm.patchValue(questionInfo)
        questionInfo.answerType ? this.controlBasedOnselectedAnswer(questionInfo.answerType as ANSWER_TYPE) : null

        if (this.extractedAnswers.includes(questionInfo.answerType)) {
            this.choices.clear()
            questionInfo.choices?.forEach((choice, index) => {
                this.addMoreOption()
                this.choices.controls[index].patchValue(choice)
            })
            return
        }

        if (questionInfo.answerType === ANSWER_TYPE.CHOICE_WITH_SCORE) {
            this.choiceWithScore.clear()
            questionInfo.scoreChoices?.forEach((choice, index) => {
                this.addMoreScoreWithChoice()
                this.choiceWithScore.controls[index].patchValue(choice)
            })
        }
    }

    controlBasedOnselectedAnswer(answer: ANSWER_TYPE) {
        this.selectedAnswerType = answer
        this.questionForm.removeControl('choices')
        this.questionForm.removeControl('scoreChoices')

        // adding control in questionForm based on extractedAnswers
        if (this.extractedAnswers.includes(answer)) {
            this.questionForm.addControl('choices', this.fb.array([this.fb.control('')]))
            return
        }

        if (answer === ANSWER_TYPE.CHOICE_WITH_SCORE) {
            this.questionForm.addControl('scoreChoices', this.fb.array([this.choiceWithScoreForm()]))
        }
    }

    get choices() {
        return (this.questionForm.get('choices') as FormArray)
    }

    get choiceWithScore() {
        return this.questionForm.get('scoreChoices') as FormArray
    }

    addMoreOption() { this.choices.push(this.fb.control('')) };
    addMoreScoreWithChoice() { this.choiceWithScore.push(this.choiceWithScoreForm()) };
    removeOption(index: number) { this.choices.removeAt(index) };
    removeChoiceWithScore(index: number) { this.choiceWithScore.removeAt(index) }

    saveQuestion() {
        if (!this.questionForm.valid) {
            this.questionForm.markAllAsTouched()
            return
        }
        this.questionData.submitForm(this.questionForm.value)
        console.log(this.questionForm.value);
        this.dialog.closeAll()
    }
}

import { DashboardService } from './../../core/services/dashboard-service/dashboard.service';
import { Component, OnInit } from '@angular/core';
import { RFX_SUPPLIER_STATUS } from 'app/enums/rfx-enums/rfx.enum';
import { EVENT_STATUS, ProjectCount } from 'app/interfaces/dashboard/dashboard.interface';
import { SupplierService } from 'app/core/services/supplier-service/supplier.service';

@Component({
    selector: 'app-event-container',
    templateUrl: './event-container.component.html',
    styleUrls: ['./event-container.component.scss']
})
export class EventContainerComponent implements OnInit {

    supplierStatusEnum = RFX_SUPPLIER_STATUS;
    totalInvited = 0;
    totalPending = 0;
    totalSubmitted = 0;
    totalRejected = 0;
    totalEnded = 0;

    constructor(
        private dashboard_service: DashboardService,
        private supplier_service: SupplierService
    ) { }

    ngOnInit(): void {
        if (this.dashboard_service.selectedCompany && this.dashboard_service.selectedCompany.code) {
            const countSubs = this.supplier_service.getActiveEventCount(this.dashboard_service.selectedCompany.code)
                .subscribe((res: EVENT_STATUS) => {

                    this.totalInvited = res.INVITED || 0;
                    this.totalPending = res.PENDING_SUBMISSION || 0;
                    this.totalSubmitted = res.SUBMITTED || 0;
                    this.totalRejected = res.REJECTED || 0;
                    this.totalEnded = res.DONE || 0;
                });
            // const countSubs = this.dashboard_service.getProjectCount(this.dashboard_service.selectedCompany.code)
            //     .subscribe((res: ProjectCount) => {
            //         if (res && res.EVENT) {
            //             let eventTotal = res.EVENT.INVITED;
            //             eventTotal += res.EVENT.PREVIEWED;

            //             this.totalInvited = eventTotal || 0;
            //             this.totalPending = res.EVENT['PENDING SUBMISSION'] || 0;
            //             this.totalSubmitted = res.EVENT.SUBMITTED || 0;
            //             this.totalRejected = res.EVENT.REJECTED || 0;
            //             this.totalEnded = res.EVENT.ENDED || 0;
            //         }
            //     });

        }
    }
}

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FuseNavigationService,
  FuseVerticalNavigationComponent
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { LoaderService } from 'app/core/services/common/loader.service';
import { SupplierProfileService } from 'app/core/services/suplier-profile/supplier-profile.service';
import { curentUserInfo, getMenu } from 'app/helper/shared-function';
import { Menu } from 'app/interfaces/common-interface/menu';
import { Supplier } from 'app/interfaces/supplier';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'classy-layout',
  templateUrl: './classy.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
  supplierInHeader$ = this.supplierProfileService.refreshSupplier$;
  isScreenSmall: boolean;
  // navigation: Navigation;
  navigation: Menu[] = [];
  supplier!: Supplier;
  isLoading: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private supplierProfileService: SupplierProfileService,
    private loader: LoaderService,
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.supplier = curentUserInfo();
    this.navigation = getMenu();

    // Subscribe to navigation data
    // this._navigationService.navigation$
    //   .pipe(takeUntil(this._unsubscribeAll))
    //   .subscribe((navigation: Navigation) => {
    //     this.navigation = navigation;
    //   });

    // Subscribe to the user service
    // this._userService.user$
    //   .pipe(takeUntil(this._unsubscribeAll))
    //   .subscribe((user: User) => {
    //     this.user = user;
    //   });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });

    this.loader.loadingSub.subscribe({
      next: (res) => {
        setTimeout(() => {
          this.isLoading = res;
        });
      }
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }
}

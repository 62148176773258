import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { AfterViewInit, Component, Input, Optional, Self, OnChanges, Output, EventEmitter } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { CustomFieldErrorMatcher } from 'app/helper/custom-validators';


/**
 * 
 * In order to use this component with formGroup please provide formControlName. eg -- formControlName="controlName". 
 * 
 * But if you want to use this component without formGroup, you can use changeEvent to get the input value in parent component .. ||
 * 
 * optional decorater  --> fieldLebel, placeholder .. |
 * 
 * < 1 > options -- To display items in dropdown, Data should be array of object. 
 * 
 * < 2 > selectedOption --  if you are not using this component with formControlaName then you can pass value to check dropdown options. 
 *       NOTE - before passing SELECTED_OPTION you have to pass OPTIONS
 *  
 * < 3 > outputPattern -- in case you need your selected value in custom object so all you have to do is pass [outputPattern]='['keyOne', 'keyTwo']'
 *                        if you want your all object than pass like this [outputPattern]='[{}]'
 *       NOTE - These key should be present in OPTIONS. 
 * 
 * < 4 > optionViewKey - let say you want to display some particular name from you object so all you have to do is  [optionViewKey]="'keyName'"
 *       NOTE - if your object contains name so by default it will display name, but you can change it.
 */
@Component({
    selector: 'app-multi-select-dropdown',
    templateUrl: './multi-select-dropdown.component.html',
    styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent implements ControlValueAccessor, AfterViewInit, OnChanges {
    @Input() fieldLabel: string
    @Input() placeholder: string

    // To knwo more info about these decorates please scroll up.. 
    @Input() options: any[] = []
    @Input() selectedOptions: string[] = []
    @Input() outputPattern: any[] = []
    @Input() optionViewKey: string = 'name'

    @Output() changeEvent = new EventEmitter()

    stateMatcher: ErrorStateMatcher

    isFieldRequired: boolean
    isFieldDisabled: boolean

    filteredOptions: any[] = []
    selectedResults: string[] = []

    /* 
    we are acquiring the FormControl of input component to get the validation status and display the error messages inside the component itself.

    When we try to acquire NgControl through DI Angular throws a circular dependency error,
    and to avoid instead of providing our component through NG_VALUE_ACCESSOR we need to set the valueAccessor in the constructor.
   */
    constructor(@Self() @Optional() public control: NgControl) {
        this.control && (this.control.valueAccessor = this);
    }

    updateMainForm = (value: any) => { }
    onTouched = () => { }

    ngOnChanges(): void {
        this.filteredOptions = this.options

        // setting default values to the dropdown, if this component is not used with formControlName..
        if (this.selectedOptions.length) this.checkDropdownOptions(this.selectedOptions)

    }

    searchHandler(input: string) {
        this.filteredOptions = this.options.filter((el: any) => {
            if (el[this.optionViewKey].toLowerCase().includes(input.toLowerCase())) {
                return el
            }
        })
    }

    checkboxHandler(selected: string[]) {
        // storing custom created object in result array
        const result: { [key: string]: string | number }[] = []

        // extracting value form OPTIONS based on selected item in dropdown 
        const selectedOptionData = selected.map((value: string) => {
            return this.options.find((el: any) => value === el[this.optionViewKey])
        })

        // creating object based on outputPattern array, where key and value is dynamically generated..
        if (this.outputPattern.length && typeof this.outputPattern[0] !== 'object') {
            selectedOptionData.forEach((selectedValue: any) => {
                const patternBasedObject: { [key: string]: string | number } = {}
                this.outputPattern.forEach((pattern: string) => {
                    patternBasedObject[pattern] = selectedValue[pattern]
                })
                result.push(patternBasedObject)
            })

            this.updateProcesssedData(result)
            return
        }

        // it will return all object related to selected item, if you pass like this [{}] in outputPattern
        if (typeof this.outputPattern[0] === 'object') {
            this.updateProcesssedData(selectedOptionData)
            return
        }

        this.updateProcesssedData(selected)
    }

    // setting default values to the dropdown 
    checkDropdownOptions(defaultReslut: any) {
        if (!defaultReslut) return

        // const outputPattern = this.outputPattern[0]
        const outputPattern = typeof this.outputPattern[0] === 'string' ? this.outputPattern[0] : this.optionViewKey
        const defaultResArr = defaultReslut.map((el: any) => {
            if (!el[outputPattern]) return
            return this.options?.find((allEl) => allEl[outputPattern] === el[outputPattern])[this.optionViewKey]
        })
        this.selectedResults = defaultResArr
    }

    updateProcesssedData(data: any) {
        this.updateMainForm(data)
        this.changeEvent.next(data)
        // console.log("selected data --- ", data);
    }

    writeValue(defaultValue: any): void {
        this.selectedResults = defaultValue
        if (!defaultValue) return

        const counter = setInterval(() => {
            if (defaultValue && defaultValue.length && this.options.length) {
                this.checkDropdownOptions(defaultValue)
                clearInterval(counter)
            }
        }, 1000)
    }

    registerOnChange(fn: any): void { this.updateMainForm = fn }
    registerOnTouched(fn: any): void { this.onTouched = fn }
    setDisabledState?(isDisabled: boolean): void {
        this.isFieldDisabled = isDisabled
    }

    ngAfterViewInit(): void {
        const timer = setTimeout(() => {
            if (this.control) {
                this.stateMatcher = new CustomFieldErrorMatcher(this.control.control);
                this.isFieldRequired = this.control?.control?.hasValidator(Validators.required)
                clearTimeout(timer)
            }
        }, 100)
    }

}

import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { downloadAndSaveFile } from 'app/helper/shared-function';
import { PdfViewerInput } from 'app/interfaces/common-interface/common-interface';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
  pdfSrc: string = '';
  loading: boolean = false;
  zoomLevel: number = 1;

  constructor(
    public dialogRef: MatDialogRef<PdfViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogInput: PdfViewerInput) { 
      this.calculateZoomLevel();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calculateZoomLevel();
  }

  ngOnInit(): void {
    if(this.dialogInput) {
      this.pdfSrc = this.dialogInput.pdfSrc;
    }
  }

  downloadPdf(): void {
    downloadAndSaveFile(this.pdfSrc, this.dialogInput?.downloadedFileName);
  }

  onProgress(event: {loaded: number; total: number}): void {
    this.loading = event.loaded < event.total;
  }

  afterLoadComplete(pdf: PDFDocumentProxy): void {
    setTimeout(() => {
        this.loading = false;
    }, 300);
  }

  calculateZoomLevel() {
    const screenWidth = window.innerWidth;
    // You can adjust these values as per your requirements
    if (screenWidth >= 2560) {
      this.zoomLevel = 1.6; // Set zoom level for large screens
    } else if (screenWidth >= 1920) {
      this.zoomLevel = 1.4; // Set zoom level for medium screens
    } else {
      this.zoomLevel = 1.2; // Set default zoom level for smaller screens
    }
  }

}

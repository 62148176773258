import { Injectable } from '@angular/core';
import { CustomHttpService } from '../common/custom-http-service/custom-http.service';
import { supplierOnboarding } from 'app/core/constants/supplier-onboard.endpoints';
import { SupplierFormResponse } from 'app/interfaces/supplier-onboarding/supplier-form-list.interface';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { SupplierPreveiewForm } from 'app/interfaces/supplier-onboarding/supplier-form.interface';

@Injectable({
  providedIn : 'root'
})
export class SupplierOnboardService {
  

  constructor(private customHttp: CustomHttpService) {}

  getSupplierFormListing(qParams?: any, searchKeyword?: string) {
    let params = new HttpParams();

    for (let key in qParams) {
      qParams[key] && (params = params.append(key, qParams[key]));
    }

    if (searchKeyword) {
      params = params.append('keyword', searchKeyword);
    }

    return this.customHttp.reactiveGet<SupplierFormResponse>(
      supplierOnboarding.supplierFormList,
      { params }
    );
  }

  supplierFormById(formId: number): Observable<SupplierPreveiewForm> {
    return this.customHttp.reactiveGet<SupplierPreveiewForm>(
      supplierOnboarding.supplierFormResponse + '/' + formId
    );
  }

  submitSupplierResponse(formData, formId: number, responseId:number) {
    const params = new HttpParams().set('formId', formId).set('responseId', responseId);
    return this.customHttp.reactivePatch(
      supplierOnboarding.submitResponse,
      formData,
      { params }
    );
  }
}

import { ControlValueAccessor, Validators, NgControl } from '@angular/forms';
import { Component, Input, Output, EventEmitter, Self, Optional, AfterViewInit } from '@angular/core';
import { INPUT_FIELD_TYPE } from 'app/enums/form-enum/form-enum';
import { ErrorStateMatcher } from '@angular/material/core';
import { CustomFieldErrorMatcher } from 'app/helper/custom-validators';

/**
 *
 * In order to use this component with formGroup please provide formControlName. eg -- formControlName="controlName".
 *
 * But if you want to use this component without formGroup, you can use changeEvent to get the input value in parent component .. ||
 *
 * optional decorater which app-input-field takes --> fieldLebel, fieldType, readOnly, placeholder .. |
 *
 * < 1 > You can provide label by using fieldLabel ..  |
 *
 * < 2 > Default type of input-box is text, you can change it into number or time by providing this enum ( INPUT_FIELD_TYPE ) to [fieldType] .. |
 *
 * < 3 > If you want to mark this field readonly than please pass [readOnly]='true' .. |
 *
 * < 4 > You can give custom placeholder name by using placeholder .. |
 *
 */
@Component({
    selector: 'app-input-field',
    templateUrl: './input-field.component.html',
    styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent implements ControlValueAccessor, Validators, AfterViewInit {
    @Input() fieldLabel: string
    @Input() fieldType: INPUT_FIELD_TYPE = INPUT_FIELD_TYPE.TEXT
    @Input() placeholder: string
    @Input() readonly: boolean = false;
    @Input() maxLength: number;
    @Input() hint: string;

    @Output() changeEvent = new EventEmitter<string | number>()

    stateMatcher: ErrorStateMatcher
    inputFieldType = INPUT_FIELD_TYPE

    initialValue: string | number = ''
    isFieldRequird: boolean
    isFieldDisabled: boolean = false


    /*
     we are acquiring the FormControl of input component to get the validation status and display the error messages inside the component itself.

     When we try to acquire NgControl through DI Angular throws a circular dependency error,
     and to avoid instead of providing our component through NG_VALUE_ACCESSOR we need to set the valueAccessor in the constructor.
    */
    constructor(@Self() @Optional() public control: NgControl) {
        this.control && (this.control.valueAccessor = this);
    }

    onChange = (value: string | number) => { };
    onTouched = () => { };

    inputHandler(inputValue: string) {
        this.initialValue = inputValue

        if (this.fieldType === this.inputFieldType.NUMBER) {
            this.onChange(Number(inputValue))
            this.changeEvent.next(Number(inputValue))
            return
        }

        this.onChange(inputValue)
        this.changeEvent.next(inputValue)
    }

    writeValue(value: string | number): void {
        this.initialValue = value
    }

    registerOnChange(fn: any): void { this.onChange = fn }
    registerOnTouched(fn: any): void { this.onTouched = fn }
    setDisabledState?(isDisabled: boolean): void { this.isFieldDisabled = isDisabled }

    ngAfterViewInit(): void {
        const timeId = setTimeout(() => {
            if (this.control) {
                this.stateMatcher = new CustomFieldErrorMatcher(this.control.control);
                this.isFieldRequird = this.control?.control?.hasValidator(Validators.required)
                clearTimeout(timeId)
            }
        })
    }

}

import { Route } from '@angular/router';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/dashboard'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

    // Redirect signed in user to the '/dashboard'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Auth routes for guests
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        loadChildren: () =>
            import('./modules/auth/auth.module').then((m) => m.AuthModule),
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        loadChildren: () =>
            import('./modules/landing/dashboard.module').then(
                (m) => m.DashboardModule
            ),
    },

    // Admin routes
    {
        path: 'dashboards',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        loadChildren: () =>
            import('./modules/admin/admin.module').then((m) => m.AdminModule),
    },
    // {
    //     path: '',
    //     canActivate: [AuthGuard],
    //     component: LayoutComponent,
    //     loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
    // }

    {
        path: 'sourcing',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        loadChildren: () =>
            import('app/modules/rfx/rfx.module').then((m) => m.RfxModule),
    },
    {
        path: 'payment-remittance-supplier',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        loadChildren: () =>
            import(
                'app/modules/payment-remittance-supplier/payment-remittance-supplier.module'
            ).then((m) => m.PaymentRemittanceSupplierModule),
    },
    {
        path: 'invoice',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        loadChildren: () =>
            import('app/modules/invoice/invoice.module').then(
                (m) => m.InvoiceModule
            ),
    },
    // {
    //     path: 'pir',
    //     canActivate: [AuthGuard],
    //     component: LayoutComponent,
    //     resolve: {
    //         initialData: InitialDataResolver,
    //     },
    //     loadChildren: () =>
    //         import(
    //             'app/modules/pir/pir.module'
    //         ).then((m) => m.PirModule),
    // },
    {
        path: 'gra',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        loadChildren: () =>
            import('app/modules/gra/gra.module').then((m) => m.GraModule),
    },
    {
        path: 'po',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        loadChildren: () =>
            import('app/modules/po/po.module').then((m) => m.PoModule),
    },
    {
        path: 'dashboard',
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        loadChildren: () =>
            import('app/modules/dashboard/dashboard.module').then(
                (m) => m.DashboardModule
            ),
    },
    {
        path: 'supplier-form',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },

        loadChildren: () =>
            import(
                './modules/supplier-onboarding/supplier-onboarding.module'
            ).then((m) => m.SupplierOnboardingModule),
    },
    {
        path: 'supplier-user',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },

        loadChildren: () =>
            import(
                './modules/supplier-user/supplier-user.module'
            ).then((m) => m.SupplierUserModule),
    },
    {
        path: 'user-guide',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        loadChildren: () =>
            import('app/modules/user-guide/user-guide.module').then(
                (m) => m.UserGuideModule
            ),
    },

    {
        path: '404-not-found',
        pathMatch: 'full',
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        loadChildren: () =>
            import(
                'app/modules/admin/pages/error/error-404/error-404.module'
            ).then((m) => m.Error404Module),
    },
    {
        path: 'contract-price',
        // canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        loadChildren: () =>
            import('app/modules/contract-price/contract-price.module').then((m) => m.ContractPriceModule),
    },
    {
        path: 'announcement',
        // canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        loadChildren: () =>
            import('app/modules/announcement/announcement.module').then((m) => m.AnnouncementModule),
    },
    { path: '**', redirectTo: '404-not-found' },
];

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseVerticalNavigationComponent } from '@fuse/components/navigation/vertical/vertical.component';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { FuseUtilsService } from '@fuse/services/utils/utils.service';
import { Supplier } from 'app/interfaces/supplier';
import { SupplierProfileStatus } from 'app/interfaces/supplier-interface/supplier-profile.interface';
import { SupplierProfileService } from 'app/core/services/suplier-profile/supplier-profile.service';
import { SupplierFormResponse } from 'app/interfaces/supplier-onboarding/supplier-form-list.interface';
import { SupplierOnboardService } from 'app/core/services/supplier-onboard-service/supplier-onboard.service';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
    selector       : 'fuse-vertical-navigation-basic-item',
    templateUrl    : './basic.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseVerticalNavigationBasicItemComponent implements OnInit, OnDestroy
{
    @Input() item: FuseNavigationItem;
    @Input() name: string;
    loggedUser: Supplier;
    supplierProfileStatusEnum = SupplierProfileStatus;
    isPendingSupplierForm: boolean;

    isActiveMatchOptions: IsActiveMatchOptions;
    private _fuseVerticalNavigationComponent: FuseVerticalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    isLinksDisabled = false;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _router: Router,
        private _fuseUtilsService: FuseUtilsService,
        private supplierProfileService: SupplierProfileService,
        private supplier_onboard: SupplierOnboardService,
        private authService: AuthService
    )
    {
        // Set the equivalent of {exact: false} as default for active match options.
        // We are not assigning the item.isActiveMatchOptions directly to the
        // [routerLinkActiveOptions] because if it's "undefined" initially, the router
        // will throw an error and stop working.
        this.isActiveMatchOptions = this._fuseUtilsService.subsetMatchOptions;

        if(this._router.url.split('?')[0] === '/dashboard/reset-password'){
            this.isLinksDisabled = true;
          }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.supplierProfileService.refreshSupplier$.subscribe((response) => {
            this.loggedUser = response;
            this._changeDetectorRef.markForCheck();
        });

        if (this.authService.accessToken && this.authService.accessToken !== '') {
            this.supplier_onboard
                .getSupplierFormListing()
                .subscribe((res: SupplierFormResponse) => {
                    const pendingItem = res.result.filter(
                        (res) => res.status === 'Pending Submission'
                    );
    
                    if (pendingItem.length) {
                        this.isPendingSupplierForm = true;
                    } else {
                        this.isPendingSupplierForm = false;
                    }
                    this._changeDetectorRef.detectChanges();
                });
        }
        
        // Set the "isActiveMatchOptions" either from item's
        // "isActiveMatchOptions" or the equivalent form of
        // item's "exactMatch" option
        this.isActiveMatchOptions =
            this.item.isActiveMatchOptions ?? this.item.exactMatch
                ? this._fuseUtilsService.exactMatchOptions
                : this._fuseUtilsService.subsetMatchOptions;

        // Get the parent navigation component
        this._fuseVerticalNavigationComponent = this._fuseNavigationService.getComponent(this.name);

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Subscribe to onRefreshed on the navigation component
        this._fuseVerticalNavigationComponent.onRefreshed.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(() => {

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}

<mat-form-field class="fuse-mat-dense w-full" floatLabel="always">
    <mat-label [ngClass]="{'danger' : control?.touched && control?.errors}" *ngIf="fieldLabel">
        {{ fieldLabel }}
    </mat-label>
    <mat-select
        [errorStateMatcher]="stateMatcher" [placeholder]="placeholder" [(ngModel)]='selectedResults' (blur)="onTouched()"
        [disabled]="isFieldDisabled" (selectionChange)="checkboxHandler($event.value)" [required]="isFieldRequired"
        [multiple]="true">
        <mat-option>
            <ngx-mat-select-search (input)="searchHandler($event.target.value)" [placeholderLabel]="placeholder || '' "
                noEntriesFoundLabel="No data found">
            </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let base of filteredOptions" [value]="base[optionViewKey]">
            {{ base[optionViewKey] | uppercase }}
        </mat-option>
    </mat-select>
</mat-form-field>
import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Console } from 'console';
@Directive({
    selector: '[appTwoDigitDecimaNumber]'
})
export class TwoDigitDecimaNumberDirective implements OnInit {
    @Input() appTwoDigitDecimaNumber!: number;

    private originalPattern = /^\d*\.?\d{0,2}$/g;
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home',
    // '-',
    'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

    private regex: RegExp;
    // private regex: RegExp = new RegExp("^\d*\.?\d{0," + this.appTwoDigitDecimaNumber + "}$/g");
    constructor(private el: ElementRef) {
        
    }
    ngOnInit(): void {
        // Replace the '2' with a dynamic value, for example, '3'
        const modifiedPatternString = this.originalPattern.source.replace('2', this.appTwoDigitDecimaNumber.toString());
        const modifiedFlags = this.originalPattern.flags;

        // Create a new regex with the modified pattern
        this.regex = new RegExp(modifiedPatternString, modifiedFlags);
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }

        let current: string = this.el.nativeElement.value;
        const position = this.el.nativeElement.selectionStart;
        const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }
}
<div class="grid w-full">
  <!-- Pre-Pr table -->
  <div class="flex flex-col flex-auto">

    <form action="" [formGroup]="columnSearchForm">
      <mat-form-field class="fuse-mat-dense md:w-4/12 w-full">
        <mat-label>Filter Result:</mat-label>
        <input matInput placeholder="Search" formControlName="keyword">
      </mat-form-field>
      <table mat-table [dataSource]="filterEventList | async" class="w-full rtr-table text-center"
        (matSortChange)="sortData($event)" matSort>
        <ng-container matColumnDef="internalReferenceNumber">
          <th class="text-sm status-dropdown-container" mat-header-cell *matHeaderCellDef>
            <div>
              <div class="pt-3" mat-sort-header>Reference Number</div>
              <mat-form-field class="w-full pt-1.5 fuse-mat-dense">
                <input matInput placeholder="Search" formControlName="internalReferenceNumber">
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="cursor-pointer" (click)="onRowSelect(element)">
            {{element.internalReferenceNumber}}
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th class="text-sm status-dropdown-container" mat-header-cell *matHeaderCellDef>
            <div>
              <div class="pt-3" mat-sort-header>Title</div>
              <mat-form-field class="w-full pt-1.5 fuse-mat-dense">
                <input matInput placeholder="Search" formControlName="title">
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="cursor-pointer">
            {{element.title}}
          </td>
        </ng-container>

        <ng-container matColumnDef="rfxSequenceId">
          <th class="text-sm status-dropdown-container" mat-header-cell *matHeaderCellDef>
            <div>
              <div class="pt-3" mat-sort-header>Event ID</div>
              <mat-form-field class="w-full pt-1.5 fuse-mat-dense">
                <input matInput placeholder="Search" formControlName="rfxSequenceId">
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="cursor-pointer">
            {{element.rfxSequenceId}}
          </td>
        </ng-container>

        <ng-container matColumnDef="eventStartDate">
          <th class="text-sm status-dropdown-container" mat-header-cell *matHeaderCellDef>
            <div class="mb-10">
              <div class="mb-10 pb-3" mat-sort-header>Start Date</div>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="cursor-pointer">
            {{element.eventStartDate}}
          </td>
        </ng-container>

        <ng-container matColumnDef="buyer">
          <th class="text-sm status-dropdown-container" mat-header-cell *matHeaderCellDef>
            <div>
              <div class="pt-3" mat-sort-header>Buyer</div>
              <mat-form-field class="w-full pt-1.5 fuse-mat-dense">
                <input matInput placeholder="Search" formControlName="buyer">
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="cursor-pointer">
            {{element.buyer}}
          </td>
        </ng-container>

        <ng-container matColumnDef="purchasingOrg">
          <th class="text-sm status-dropdown-container" mat-header-cell *matHeaderCellDef>
            <div>
              <div class="pt-3" mat-sort-header>Company</div>
              <mat-form-field class="w-full pt-1.5 fuse-mat-dense">
                <input matInput placeholder="Search" formControlName="purchasingOrg">
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="cursor-pointer">
            {{element.purchasingOrg}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="eventColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: eventColumns" (click)="onRowSelect(row)" (contextmenu)="onContextMenu($event, row)" class="hover:bg-gray-200"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="6">No data found</td>
        </tr>
      </table>

      <div
    style="visibility: hidden; position: fixed"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="contextMenu"
  ></div>
  <mat-menu #contextMenu="matMenu" #contextMenu2="matMenu">
    <ng-template matMenuContent let-item="item">
      <button mat-menu-item (click)="onContextMenuAction(item)">
        Open in new tab
      </button>
    </ng-template>
  </mat-menu>
  
    </form>

    <div class="flex justify-end">
      <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" (page)="pageChanged($event)">
      </mat-paginator>
    </div>
  </div>
</div>
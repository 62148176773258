<mat-tab-group class="sm:px-2">
  <mat-tab [label]="'Invited (' + totalInvited + ')'">
    <ng-template matTabContent>
      <app-event-table [status]="supplierStatusEnum.INVITED"></app-event-table>
    </ng-template>
  </mat-tab>

  <mat-tab [label]="'Pending Submission (' + totalPending + ')'">
    <ng-template matTabContent>
      <app-event-table [status]="supplierStatusEnum.PENDINGSUBMISSION"></app-event-table>
    </ng-template>
  </mat-tab>

  <mat-tab [label]="'Submitted'">
    <ng-template matTabContent>
      <app-event-table [status]="supplierStatusEnum.SUBMITTED"></app-event-table>
    </ng-template>
  </mat-tab>

  <mat-tab [label]="'Rejected'">
    <ng-template matTabContent>
      <app-event-table [status]="supplierStatusEnum.REJECTED"></app-event-table>
    </ng-template>
  </mat-tab>

  <mat-tab [label]="'Ended'">
    <ng-template matTabContent>
      <app-event-table [status]="supplierStatusEnum.ENDED"></app-event-table>
    </ng-template>
  </mat-tab>

  <!-- <mat-tab [label]="'Ended (0)'">
    <ng-template matTabContent>
    </ng-template>
  </mat-tab> -->
</mat-tab-group>

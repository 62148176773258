import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { USER } from 'app/core/constants/api-constant';
import { User } from 'app/interfaces/users';
import { UserPayload } from 'app/interfaces/users-payload';
import { environment } from 'environments/environment';
import { BehaviorSubject, map, Observable, ReplaySubject, tap } from 'rxjs';
import { CustomHttpService } from '../common/custom-http-service/custom-http.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    url = environment.URL;
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    constructor(
        private http: HttpClient,
        private customService: CustomHttpService
    ) { }

    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    /**
       * Get the current logged in user data
       */
    get(): Observable<User> {
        return this.http.get<User>('api/common/user').pipe(
            tap((user) => {
                this._user.next(user);
            })
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this.http.patch<User>('api/common/user', { user }).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }

    getLoggedUserPayload(): Observable<UserPayload> {
        return this.customService.reactiveGet<UserPayload>(USER.USER_DATA_PAYLOAD);
    }

    getUserList(page?: number, size?: number, searchText?: string): Observable<any> {
        page = !page ? 1 : page;
        size = !size ? 10 : size;
        let url = `${USER.USER_LIST}?page=${page}&size=${size}`;
        if (searchText != null && searchText !== '') {
            url += `&keyword=${searchText}`;
        }
        return this.customService.reactiveGet<any>(`${url}`);
    }

}

import { AbstractControl, FormGroupDirective, NgForm, ValidationErrors } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { CUSTOM_ERROR_KEY } from "app/enums/custom-validator-key-enum/validator-error-key.enum";
import { decimalRegex } from "./regex";

export class CustomFieldErrorMatcher implements ErrorStateMatcher {
    constructor(private customControl: AbstractControl) { }
    isErrorState(control: AbstractControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return this.customControl && (this.customControl.touched || this.customControl.dirty) && this.customControl.invalid;
    }
}

// validation is not working is if default value is false so here is the solution.
export function validateFalseValue(control: AbstractControl): ValidationErrors | null {
    if (control.value === false) return { required: true }
    return null;
}

export function numberMaxLength(maxLen: number): ValidationErrors | null {
    return function (control: AbstractControl) {
        if (!control.value) return null;
        const controlValue = control.value.toString()
        const maxLength = Array.from(Array(maxLen).keys())

        if (controlValue.length > maxLength.length) {
            return { [CUSTOM_ERROR_KEY.NUMBER_MAX_LENGTH]: true, touched: true }
        }
    }
}

export function decimalValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;
    const controlValue = control.value.toString() as string

    if (controlValue.includes('.')) return { [CUSTOM_ERROR_KEY.DECIMAL_ERROR]: true }
    else return null
}

export function numberValidator(control: AbstractControl): ValidationErrors | null {    
    if (isNaN(control.value)) {
        return { [CUSTOM_ERROR_KEY.NUMBER_VALIDATION]: true }
    }
    return null
}

export function emptyArrValidator(control: AbstractControl): ValidationErrors | null {    
    if (!control.value?.length) {
        return { emptyArrayError : true }
    }
    return null
}
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnlyNumberDirective } from 'app/directives/only-number-directive';
import { MaterialModule } from 'app/modules/material/material.module';
import { EventContainerComponent } from './event-container/event-container.component';
import { EventTableComponent } from './event-container/event-table/event-table.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { LoaderComponent } from './loader/loader.component';
import { MultilineErrorSnackComponent } from './multiline-error-snack/multiline-error-snack.component';
import { AmpmPipe } from './pipes/ampm.pipe';
import { LocalTimePipe } from './pipes/local-time.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { TwoDigitDecimaNumberDirective } from 'app/directives/two-digit-decima-number.directive';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer/pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BlockCopyPasteDirective } from 'app/directives/block-copy-paste.directive';

const components = [
  OnlyNumberDirective,
  TwoDigitDecimaNumberDirective,
  BlockCopyPasteDirective,
  EventContainerComponent,
  EventTableComponent,
  AmpmPipe,
  LocalTimePipe,
  LoaderComponent,
  MultilineErrorSnackComponent,
  OrderByPipe,
  ImageViewerComponent
];

@NgModule({
  declarations: [
    ...components,
    PdfViewerComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ...components
  ]
})
export class SharedModule {
}

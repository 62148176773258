import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { apiConst  }
import {
  DashboardMeetingList,
  ProjectCount,
} from 'app/interfaces/dashboard/dashboard.interface';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import * as apiConstants from '../../constants/dashboard-api-const';
import {
  PurchaseOrg,
  PurchaseOrgLocalStorage,
} from 'app/interfaces/purchase-org';
import { CustomHttpService } from '../common/custom-http-service/custom-http.service';
import { SUPPLIER } from 'app/core/constants/api-constant';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  sharePurchasingOrg = new BehaviorSubject(null);
  constructor(
    private customHttp: CustomHttpService,
    private http: HttpClient
    ) {}

  set selectedCompany(company: PurchaseOrgLocalStorage) {
    sessionStorage.setItem('selected-company', JSON.stringify(company));
  }

  get selectedCompany(): PurchaseOrg {
    return JSON.parse(sessionStorage.getItem('selected-company')) ?? null;
  }

  getProjectCount(purchasingOrgCode): Observable<ProjectCount> {
    let params = new HttpParams().set('purchasingOrgCode', purchasingOrgCode);
    params = params.appendAll({ 'status[]': 'INVITED' });
    params = params.appendAll({ 'status[]': 'REJECTED' });
    params = params.appendAll({ 'status[]': 'SUBMITTED' });
    params = params.appendAll({ 'status[]': 'PENDING SUBMISSION' });
    params = params.appendAll({ 'status[]': 'ENDED' });
    return this.customHttp.reactiveGet<ProjectCount>(
      apiConstants.DB_TAB_ENDPOINT.COUNT,
      { params }
    );
  }

  getDashboardEvent(
    status: string,
    purchaseOrg: number,
    keyword?,
    pageNumber?: number,
    size?: number
  ) {
    let params = new HttpParams()
      .set('status', status)
      .set('purchaseOrg', purchaseOrg)
      .set('keyword', keyword)
      .set('page', pageNumber || 1);
    if (keyword) params = params.append('keyword', keyword);
    size && (params = params.append('size', size));
    return this.customHttp.reactiveGet(apiConstants.DB_TAB_ENDPOINT.EVENT, {
      params,
    });
  }

  getEventCount() {
    return this.customHttp.reactiveGet(
      apiConstants.DB_TAB_ENDPOINT.EVENT_COUNT
    );
  }

  getSupplierTermsAndConditions() {
    return this.customHttp.reactiveGet(
      apiConstants.SUPPLIER_ONBOARDING.TERMS_N_CONDITIONS
    );
  }

  getAnnouncementListing(payload: any) {
    let params = new HttpParams().set('page', payload.page).set('size', payload.size);
    if (payload.keyword) {
      params = params.set('keyword', payload.keyword);
    }
    if (payload.fromDate) {
      params = params.set('fromDate', payload.fromDate);
    }
    if (payload.toDate) {
      params = params.set('toDate', payload.toDate);
    }
    if (payload.status) {
      params = params.set('status', payload.status);
    }
    if (payload.sortBy && payload.sortBy !== '' && payload.sortOrder && payload.sortOrder !== '') {
      params = params.set('sortBy', payload.sortBy);
      params = params.set('sortOrder', payload.sortOrder.toUpperCase());
    }

    return this.customHttp.reactiveGet(
      apiConstants.ANNOUNCEMENT.LIST, { params }
    );
  }

  getAnnouncementDetails(id: number) {
    return this.customHttp.reactiveGet(
      apiConstants.ANNOUNCEMENT.DETAILS +'/'+ id,
    );
  }

  downloadAnnouncementPdf(id: number) {
    return lastValueFrom(this.http.get(apiConstants.ANNOUNCEMENT.PDF+'?id='+ id, {
      responseType: 'blob',
      observe: 'response',
    }));
  }

  getPurchaseOrder(
    status: string,
    purchaseOrg: number,
    search?: string,
    pageNumber?: number,
    size: number = 10
  ) {
    let params = new HttpParams()
      .set('status', status)
      .set('purchasingOrgCode', purchaseOrg)
      .set('page', pageNumber || 1);
    search && (params = params.append('keyword', search));
    size && (params = params.append('size', size));
    return this.customHttp.reactiveGet(
      apiConstants.DB_TAB_ENDPOINT.PURCHASE_ORDER,
      { params }
    );
  }

  getDashboardGRA(
    status: string,
    purchaseOrg: number,
    search: string = '',
    pageNumber?: number,
    size?: number
  ) {
    let params = new HttpParams()
      .set('status', status)
      .set('purchaseOrg', purchaseOrg)
      .set('page', pageNumber || 1);
    // .set('keyword', search)
    search && (params = params.append('keyword', search));
    size && (params = params.append('size', size));
    return this.customHttp.reactiveGet(apiConstants.DB_TAB_ENDPOINT.GRA, {
      params,
    });
  }

  getDashboardInvoice(
    status: string,
    company: number,
    search: string = '',
    pageNumber?,
    size?: number
  ) {
    let params = new HttpParams()
      .set('status', status)
      .set('company', company)
      .set('page', pageNumber || 1);
    if (search) params = params.append('keyword', search);
    if (size) params = params.append('size', size);
    return this.customHttp.reactiveGet(apiConstants.DB_TAB_ENDPOINT.INVOICE, {
      params,
    });
  }

  getDashboardMeetingSchedule(): Observable<DashboardMeetingList[]> {
    return this.customHttp.reactiveGet<DashboardMeetingList[]>(
      apiConstants.DB_TAB_ENDPOINT.MEETING_SCHEDULE
    );
  }

  getTotalTasks(code: number) {
    let params = new HttpParams();
    params = params.append('purchasingOrgCode', code);
    return this.customHttp.reactiveGet(
      apiConstants.DB_TAB_ENDPOINT.TASK_COUNT,
      { params }
    );
  }

  acceptTnc() {
    return this.customHttp.reactivePatch(SUPPLIER.ACCEPT_TNC, null);
  }
}

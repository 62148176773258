<mat-form-field class="fuse-mat-dense w-full" floatLabel="always">
    <!-- simple dropdown -->
    <mat-label [ngClass]="{'danger' : control?.touched && control?.errors}" *ngIf="fieldLabel">{{ fieldLabel }}
    </mat-label>
    <mat-select [errorStateMatcher]="stateMatcher" [placeholder]="placeholder" [(ngModel)]='initialValue'
        (blur)="onTouched()" *ngIf="dropdownType === DROPDOWN_TYPE.SIMPLE && !addMoreDo"
        (selectionChange)="generateDpResult($event)" [disabled]="isFieldDisabled" [required]="isFieldRequired">
        <!-- <mat-option *ngFor="let opt of options" [value]="opt[optionViewKey]"
            [ngStyle]="useUppercase ? { 'text-transform': 'uppercase' } : {'text-transform': 'capitalize'}">{{
            opt[optionViewKey] | titlecase }}</mat-option> -->
        <mat-option *ngFor="let opt of options" [value]="opt[optionViewKey]">
          <span *ngIf="(opt[optionViewKey] | typeof) === 'number' || useDefaultCasing ">
            {{(opt[optionViewKey])}}
          </span>
          <span *ngIf="(opt[optionViewKey] | typeof) === 'string' && !useDefaultCasing ">
            <span>{{useUppercase ? (opt[optionViewKey] | uppercase) : (opt[optionViewKey] | titlecase )}}</span>
          </span>
        </mat-option>
    </mat-select>
    <mat-select [errorStateMatcher]="stateMatcher" [placeholder]="placeholder" [(ngModel)]='initialValue'
        (blur)="onTouched()" *ngIf="dropdownType === DROPDOWN_TYPE.SIMPLE && addMoreDo"
        (selectionChange)="generateDpResult($event)" [disabled]="isFieldDisabled" [required]="isFieldRequired">
        <mat-option *ngFor="let opt of options" [value]="opt['doNumber']">{{ opt['doNumber'] +"-"+ opt['sapGraNumber']
            }}</mat-option>
    </mat-select>

    <!-- search dropdown  -->
    <mat-select *ngIf="dropdownType === DROPDOWN_TYPE.SEARCH" [errorStateMatcher]="stateMatcher"
        [placeholder]="placeholder" [(ngModel)]='initialValue' (blur)="onTouched()" [disabled]="isFieldDisabled"
        (selectionChange)="generateDpResult($event)" [required]="isFieldRequired">
        <mat-option>
            <ngx-mat-select-search (input)="searchHandler($event.target.value)" [placeholderLabel]="placeholder || '' "
                noEntriesFoundLabel="No data found">
            </ngx-mat-select-search>
        </mat-option>
        <!-- <mat-option *ngFor="let base of filteredOptions" [value]="base[optionViewKey]"
            [ngStyle]="useUppercase ? { 'text-transform': 'uppercase' } : {'text-transform': 'capitalize'}">
            {{ base[optionViewKey] | titlecase }}
        </mat-option> -->
        <mat-option *ngFor="let base of filteredOptions" [value]="base[optionViewKey]">
          <span *ngIf="(base[optionViewKey] | typeof) === 'number' || useDefaultCasing ">
              {{(base[optionViewKey])}}
          </span>
          <span *ngIf="(base[optionViewKey] | typeof) === 'string' && !useDefaultCasing ">
              <span>{{useUppercase ? (base[optionViewKey] | uppercase) : (base[optionViewKey] | titlecase)}}</span>
          </span>
      </mat-option>
    </mat-select>
</mat-form-field>

import { Pipe, PipeTransform } from '@angular/core';
import { continentalTimeToAmPM } from 'app/helper/shared-function';

@Pipe({
  name: 'localTime'
})
export class LocalTimePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {        
    return continentalTimeToAmPM(value);
}

}

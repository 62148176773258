import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UtilService } from 'app/core/services/util.service';
import { DROPDOWN_TYPE } from 'app/enums/form-enum/form-enum';
import { ViewerTypeEnum } from 'app/enums/pre-pr-enums/viewer-type.enum';
import { Member } from 'app/interfaces/rfx-interface/rfx-template.interface';
import { Subscription } from 'rxjs';
import { TeamMemberViewerTypeComponent } from './team-member-viewer-type/team-member-viewer-type.component';

@Component({
  selector: 'app-team-member-form',
  templateUrl: './team-member-form.component.html',
  styleUrls: ['./team-member-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TeamMemberFormComponent,
      multi: true,
    },
  ],
})
export class TeamMemberFormComponent implements ControlValueAccessor {
  @Input() userList: any[] = [];

  teamMemberSubs$: Subscription[] = [];
  DROPDOWN_TYPE = DROPDOWN_TYPE;

  selectedMembers: Member[] = [];
  duplicateUserErr: string;

  updateMainForm = (formValue: Member[]) => {};

  constructor(public dialog: MatDialog, private util_service: UtilService) {}

  addTeamMember(selectedTeam: { name: string; userId: number }): void {
    let isMemberAlreadyExist = false;
    this.selectedMembers.forEach((member) => {
      if (member.name === selectedTeam.name) isMemberAlreadyExist = true;
    });

    if (isMemberAlreadyExist) {
      this.duplicateUserErr = selectedTeam.name;
      this.util_service.showErrorSnack('Member is already selected');
      return;
    } else this.duplicateUserErr = '';

    const dialogRef = this.dialog.open(TeamMemberViewerTypeComponent, {
      autoFocus: false,
      width: '300px',
      backdropClass: 'transparent-backdrop',
    });

    const dRef = dialogRef
      .afterClosed()
      .subscribe((viewStatus: ViewerTypeEnum) => {
        if (!viewStatus) return;
        this.selectedMembers.push({ ...selectedTeam, viewStatus });

        this.updateMainForm(this.selectedMembers);
      });

    this.teamMemberSubs$.push(dRef);
  }

  removeTeamMember(index: number): void {
    this.selectedMembers.splice(index, 1);
    this.updateMainForm(this.selectedMembers);
  }

  writeValue(members: Member[]): void {
    if (!members) return;
    const mappedMember = members.map((member: Member) => {
      return {
        name: member?.user?.name,
        userId: member.userId,
        viewStatus: member.viewStatus,
      };
    });

    this.selectedMembers = mappedMember;
    this.updateMainForm(mappedMember);
  }

  registerOnChange(fn: any): void {
    this.updateMainForm = fn;
  }
  registerOnTouched(fn: any): void {}
}

import { PaginationResponse } from './../../../interfaces/pagination-response';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'app/config';
import {
  COMMON_ENDPOINT,
    SUPPLIER, SUPPLIER_RESPONSE, SUPPLIER_USER
} from 'app/core/constants/api-constant';
import { RFX_SUPPLIER_STATUS } from 'app/enums/rfx-enums/rfx.enum';
import { RfxForm } from 'app/interfaces/rfx-interface/rfx-form.interface';
import { SupplierBOQ } from 'app/interfaces/supplier-interface/supplier-boq';
import { SupplierEvent } from 'app/interfaces/supplier-interface/supplier-event';
import {
    SupplierMeetingAttendees,
    SupplierMessages,
    SupplierResponse
} from 'app/interfaces/supplier-interface/supplier-response';
import { Observable } from 'rxjs/internal/Observable';
import { CustomHttpService } from '../common/custom-http-service/custom-http.service';
import { DownloadService } from '../common/download.service';
import { curentUserInfo } from 'app/helper/shared-function';
import { Supplier, SupplierRoleInterface } from 'app/interfaces/supplier';
import { User } from 'app/interfaces/users';
import { UtilService } from '../util.service';
import { ParentRight } from 'app/interfaces/supplier-access-right-interface/supplier-access-right.interface';

@Injectable({
    providedIn: 'root',
})
export class SupplierService {
    constructor(
        private customService: CustomHttpService,
        private downloadService: DownloadService,
        private utils: UtilService
    ) { }

    getSupplierInvitationByRfxId(rfxId: number): Observable<RfxForm> {
        return this.customService.reactiveGet(
            `${SUPPLIER.SUPLLIER_INVITATION}/${rfxId}`
        );
    }

    acceptRejectInvitationByRfxId(
        rfxSupplierId: number,
        rfxId: number,
        supplierName: string,
        body: { status: RFX_SUPPLIER_STATUS; remark?: string }
    ): Observable<any> {
        let params = new HttpParams().set('rfxId', rfxId).set('supplierName', supplierName);
        return this.customService.reactivePatch(
            `${SUPPLIER.SUPLLIER_INVITATION_ACTION}/${rfxSupplierId}`,
            body,
            { params }
        );
    }

    getSupplierEvents(
        columnFilters: any
    ): Observable<PaginationResponse<Array<SupplierEvent>>> {
        let params = new HttpParams();
        Object.keys(columnFilters).forEach(key => {
            params = params.append(key, columnFilters[key]);
        });
        return this.customService.reactiveGet<PaginationResponse<Array<SupplierEvent>>>(
            `${SUPPLIER.SUPLLIER_EVENT_LIST}`, { params }
        );
    }

    getSupplierSourcingList(pageIndex: number, pageSize: number, sortBy: string | undefined, sortOrder: string | undefined,
        columnFilters: any): Observable<PaginationResponse<Array<SupplierEvent>>> {
        let params = new HttpParams().set('page', pageIndex).set('size', pageSize);
        if (sortBy && sortBy !== '' && sortOrder && sortOrder !== '') {
            params = params.set('sortBy', sortBy);
            params = params.set('sortOrder', sortOrder.toUpperCase());
        }

        Object.keys(columnFilters).forEach(key => {
            if (key === 'status') {
                params = params.append(`fq=rfx.${key}`, columnFilters[key].join(','));
            } else if (key === 'purchasingOrg') {
                params = params.append('fq=purchOrg.name', columnFilters[key]);
            } else if (key === 'buyer') {
                params = params.append('fq=creator.name', columnFilters[key]);
            } else {
                params = params.append(`fq=rfx.${key}`, columnFilters[key]);
            }
        });
        return this.customService.reactiveGet<PaginationResponse<Array<SupplierEvent>>>(
            `${SUPPLIER.SUPLLIER_SOURCING_LIST}`,
            { params }
        );
    }

    downloadBQExcel(supplierBOQ: SupplierBOQ): void {
        this.downloadService.downloadExcel(
            `${GlobalVariable.BASE_URL}${SUPPLIER_RESPONSE.SUPPLIER_RESPONSE_DOWNLOAD_BOQ_EXCEL}`,
            'Bill of Quantity',
            supplierBOQ
        );
    }

    uploadBQExcel(supplierResponseBoqId: number, file: any): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.customService.reactivePost(
            `${SUPPLIER_RESPONSE.SUPPLIER_RESPONSE_UPLOAD_BOQ}?supplierResponseBoqId=${supplierResponseBoqId}`,
            formData,
            {
                headers: { Accept: '*/*' },
            }
        );
    }

    supplierUpdateAttendance(
        attendance: SupplierMeetingAttendees[]
    ): Observable<any> {
        return this.customService.reactivePatch(
            `${SUPPLIER_RESPONSE.SUPPLIER_UPDATE_ATTENDANCE}`,
            attendance
        );
    }

    supplierSendMessage(supplierMessage: SupplierMessages, rfxId: number): Observable<any> {
        const supplierName = curentUserInfo().companyName;
        const params = new HttpParams().set('supplierName', supplierName).set('rfxId', rfxId)
        return this.customService.reactivePatch(
            `${SUPPLIER_RESPONSE.SUPPLIER_SEND_MESSAGE}`, supplierMessage, { params }
        );
    }

    getSupplierResponseMessages(
        rfxId: number
    ): Observable<Array<SupplierResponse>> {
        return this.customService.reactiveGet<Array<SupplierResponse>>(
            `${SUPPLIER_RESPONSE.GET_SUPPLIER_RESPONSE_MESSAGES}?rfxId=${rfxId}`
        );
    }

    getMessageHistory(
        supplierResponseId: number
    ): Observable<SupplierResponse> {
        return this.customService.reactiveGet(
            `${SUPPLIER_RESPONSE.GET_MESSAGE_HISTORY}?supplierResponseId=${supplierResponseId}`
        );
    }

    createSupplierResponse(supplierResponse: SupplierResponse): Observable<any> {
        return this.customService.reactivePost(
            `${SUPPLIER_RESPONSE.SUPPLIER_RESPONSE_CREATE}`,
            supplierResponse
        );
    }

    updateSupplierResponse(supplierResponse: SupplierResponse): Observable<any> {
        return this.customService.reactivePatch(
            `${SUPPLIER_RESPONSE.SUPPLIER_RESPONSE_UPDATE_ONE}`,
            supplierResponse
        );
    }

    submitSupplierResponse(rfxSupplierId: number, supplierResponseId: number): Observable<any> {
      const params = new HttpParams().set('responseId', supplierResponseId)
        return this.customService.reactivePatch(
            `${SUPPLIER_RESPONSE.SUPPLIER_RESPONSE_SUBMIT}/${rfxSupplierId}`, null, {params});
    }

    getSupplierResponseById(
        supplierResponseId: number
    ): Observable<SupplierResponse> {
        return this.customService.reactiveGet(
            `${SUPPLIER_RESPONSE.SUPPLIER_RESPONSE_GET_ONE}?responseId=${supplierResponseId}`
        );
    }

    getSupplierResponseByRfxId(rfxId: number): Observable<SupplierResponse> {
        return this.customService.reactiveGet(
            `${SUPPLIER_RESPONSE.SUPPLIER_RESPONSE_CHECK_IF_RESPONDED}?rfxId=${rfxId}`
        );
    }

    getRfxFormDetailsById(rfxId: number): Observable<any> {
        return this.customService.reactiveGet(`${SUPPLIER.RFX_BY_ID}/${rfxId}`);
    }

    getActiveEventCount(purchasingOrgCode: number): Observable<any> {
        return this.customService.reactiveGet(`${SUPPLIER.ACTIVE_EVENT_COUNT}?purchasingOrgCode=${purchasingOrgCode}`);
    }

    getSupplierProfileStatus(vendorCode: string, userName: string): Observable<any> {
      const params = new HttpParams().set('vendorCode', vendorCode).set('userName', userName)
      return this.customService.reactiveGet(`${SUPPLIER.PROFILE_STATUS}`, { params });
    }

    // temporary need to remove
    getSupplierRoleList(
    ): Observable<SupplierRoleInterface[]> {
      return this.customService.reactiveGet<SupplierRoleInterface[]>(`${COMMON_ENDPOINT.ROLE_BY_SUPPLIER}`);
    }

    createUpdateSupplierUser(supplier: Supplier): Observable<any> {
      const url = `${SUPPLIER_USER.SUPPLIER_USER}`;
      if (supplier.id) {
        return this.customService.reactivePatch<any>(`${url}/${supplier.id}`, supplier);
      } else {
        return this.customService.reactivePost<any>(`${url}`, supplier);
      }
    }

    getSupplierUser(supplierId: number): Observable<Supplier> {
      return this.customService.reactiveGet<Supplier>(`${SUPPLIER_USER.SUPPLIER_USER}/${supplierId}`);
    }

    getSupplierUserList(
      page?: number,
      size?: number,
      searchText?: string
    ): Observable<PaginationResponse<Supplier[]>> {
      page = !page ? 1 : page;
      size = !size ? 10 : size;
      let url = `${SUPPLIER_USER.SUPPLIER_USER_LIST}?page=${page}&size=${size}`;
      if (!this.utils.isNullOrEmpty(searchText)) {
        url += `&keyword=${searchText}`;
      }
      return this.customService.reactiveGet<PaginationResponse<Supplier[]>>(`${url}`);
    }

    exportSupplierUser(keyword: string): void {
      let url: string = SUPPLIER_USER.EXPORT_SUPPLIER_USER;
      if (keyword) {
        url = `${url}?keyword=${keyword}`;
      }
      this.downloadService.downloadExcelByUrl(`${url}`, 'Supplier User');
    }

    getSupplierModuleListing(): Observable<ParentRight[]> {
      return this.customService.reactiveGet<ParentRight[]>(`${SUPPLIER.SUPPLIER_MODULE_LISTING}`);
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ViewerTypeEnum } from 'app/enums/pre-pr-enums/viewer-type.enum';
import { generateArrayFromEnum } from 'app/helper/shared-function';

@Component({
  selector: 'app-team-member-viewer-type',
  templateUrl: './team-member-viewer-type.component.html',
  styleUrls: ['./team-member-viewer-type.component.scss'],
})
export class TeamMemberViewerTypeComponent implements OnInit {
  viewerType = generateArrayFromEnum(ViewerTypeEnum);
  selectedViewer: ViewerTypeEnum;

  constructor(private dialogRef: MatDialogRef<TeamMemberViewerTypeComponent>) {}

  ngOnInit(): void {}

  onSubmit(): void {
    if (!this.selectedViewer) return;
    this.dialogRef.close(this.selectedViewer);
  }
}

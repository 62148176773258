import { BooleanInput } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { SupplierProfileService } from 'app/core/services/suplier-profile/supplier-profile.service';
import { MODULE_ACCESS_CHILD_NAMES, MODULE_ACCESS_NAMES } from 'app/enums/common-enums/common-enum';
import { curentUserInfo, hasAccess, hasUserLogin } from 'app/helper/shared-function';
import { Supplier } from 'app/interfaces/supplier';
import { SupplierProfileStatus } from 'app/interfaces/supplier-interface/supplier-profile.interface';
import { Subject } from 'rxjs';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    loggedUser: Supplier;
    isUserLogin = false;
    hasSupplierProfileAccess = false;
    supplierProfileStatusEnum = SupplierProfileStatus;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private supplierProfileService: SupplierProfileService,
        private authService: AuthService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loggedUser = curentUserInfo();
        this.supplierProfileService.refreshSupplier$.subscribe((response) => {
            this.loggedUser = response;
            this._changeDetectorRef.markForCheck();
        });
        
        if (hasUserLogin()) {
            this.isUserLogin = true;
        }
        this.hasSupplierProfileAccess = hasAccess(MODULE_ACCESS_NAMES.SUPPLIER_PROFILE, MODULE_ACCESS_CHILD_NAMES.NORMAL, MODULE_ACCESS_CHILD_NAMES.VIEWER);

        if (this.authService.accessToken) {
            this.supplierProfileService.isUpdateRequired().subscribe((response) => {
                this.loggedUser.updateRequired = response;
                this._changeDetectorRef.markForCheck();
            });
        }
        
        // Subscribe to user changes
        // this._userService.user$
        //   .pipe(takeUntil(this._unsubscribeAll))
        //   .subscribe((user: User) => {
        //     this.user = user;

        //     // Mark for check
        //     this._changeDetectorRef.markForCheck();
        //   });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign out
     */
    signOut(): void {
        this._router.navigate(['/sign-out']);
    }
    signIn(): void {
        this._router.navigate(['/sign-in']);
    }
}

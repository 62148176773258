<!-- Button -->
<!-- <div *ngIf="isDotBLinking" class="pos-i"></div> -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative">
    <img class="w-7 h-7" *ngIf="showAvatar && loggedUser.profileImg" [src]="loggedUser.profileImg" />
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <mat-icon class="pos-danger pos-danger-first"
      *ngIf="loggedUser?.updateRequired">warning</mat-icon>
  </span>
</button>
<div >
<mat-menu [xPosition]="'before'" #userActions="matMenu" class="menu-container"  >
  <button mat-menu-item *ngIf="isUserLogin">
    <span class="flex flex-col leading-none">
      <span>Signed in as</span>
      <span class="mt-1.5 text-md font-medium">
        {{loggedUser?.companyName}}
      </span>
    </span>
  </button>
  <mat-divider class="my-2" *ngIf="isUserLogin"></mat-divider>
  <!-- <span class="absolute left-[31px] bottom-[11px] w-2 h-2 rounded-full bg-green-500"></span> -->
  <!-- 'bg-green-500': ['Pending Approval', 'Active', 'Suspended', 'Blacklist', 'Inactive'].includes(loggedUser?.status), -->

  <button mat-menu-item *ngIf="isUserLogin && hasSupplierProfileAccess" routerLink="/dashboard/profile">
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span>Profile</span>
    <mat-icon class="pos-danger pos-danger-second"
      *ngIf="loggedUser?.updateRequired">warning</mat-icon>
  </button>
  <!-- <div *ngIf="isDotBLinking" id="blink" class="pos-ii" ></div> -->
  <mat-divider class="my-2" *ngIf="hasSupplierProfileAccess"></mat-divider>
  <button mat-menu-item (click)="signOut()" *ngIf="isUserLogin">
    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
    <span>Sign out</span>
  </button>
  <button mat-menu-item (click)="signIn()" *ngIf="!isUserLogin">
    <mat-icon [svgIcon]="'heroicons_outline:login'"></mat-icon>
    <span>Sign In</span>
  </button>
</mat-menu>
</div>
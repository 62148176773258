import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { PasswordSetting } from 'app/interfaces/password-setting-interface';
import { environment } from 'environments/environment';
import { catchError, map, Observable, of, switchMap, throwError } from 'rxjs';
import { AUTH_ENDPOINT } from '../constants/api-constant';
import { UserService } from '../services/user-service/user.service';
import { DowntimeMessage } from 'app/interfaces/common-interface/common-interface';
import { ApiUrls } from 'app/config';

@Injectable()
export class AuthService {

  showLoader = true;
  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    if (token){
      sessionStorage.setItem('accessToken', token);
    }
  }

  get accessToken(): string {
    return sessionStorage.getItem('accessToken') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param userName
   */
  forgotPassword(userName: string): Observable<any> {
    return this._httpClient.post(environment.URL + AUTH_ENDPOINT.FORGOT_PASSWORD, { "userName": userName });
  }

  /**
   * Validate password Token
   *
   * @param token
   */
  validatePasswordToken(token: string): Observable<any> {
    return this._httpClient.post(environment.URL + AUTH_ENDPOINT.VALIDATE_PASSWORD_TOKEN, { "token": token });
  }

  /**
   * Get password validation setting
   *
   * @param token
   */
  getPasswordValidationSetting(): Observable<PasswordSetting> {
    return this._httpClient.get<PasswordSetting>(environment.URL + AUTH_ENDPOINT.GET_PASSWORD_VALIDATION_SETTING);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetNewPassword(userName: string, password: string): Observable<any> {
    return this._httpClient.post(environment.URL + AUTH_ENDPOINT.RESET_NEW_PASSWORD, { "userName": userName, "password": password });
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post(environment.URL + AUTH_ENDPOINT.RESET_PASSWORD, password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this.accessToken) {
      return throwError('User is already logged in.');
    }

    return this._httpClient.post(environment.URL + AUTH_ENDPOINT.USER_LOGIN, credentials).pipe(
      switchMap((response: any) => {
        localStorage.removeItem("auto-logout");
        // Store the access token in the local storage
        this.accessToken = response.tokn;

        // Store the user on the user service
        this._userService.user = response.supplier;

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Renew token
    return this._httpClient.post(environment.URL + AUTH_ENDPOINT.REFRESH_ACCESS_TOKEN, {
      accessToken: this.accessToken
    }).pipe(
      catchError(() =>

        // Return false
        of(false)
      ),
      switchMap((response: any) => {
        localStorage.removeItem("auto-logout");
        // Store the access token in the local storage
        this.accessToken = response.accessToken;

        // Store the user on the user service
        this._userService.user = response.user;

        // Return true
        return of(true);
      })
    );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.clear();
    sessionStorage.clear();
        
    localStorage.setItem("auto-logout", "true");
    // Set the authenticated flag to false
    // console.log("auth3", localStorage.getItem('accessToken'));
    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
    return this._httpClient.post(environment.URL + AUTH_ENDPOINT.SIGN_UP, user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post(environment.URL + AUTH_ENDPOINT.UNLOCK_SESSION, credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this.accessToken) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }

  //Downtime message
  getDowntimeMessage(): Observable<DowntimeMessage> {
    const params = new HttpParams().set('service', 'supplier'); 
    return this._httpClient.get<DowntimeMessage>(`${ApiUrls.DOWNTIME_MESSAGE}?${ params }`)
    .pipe(
        map(response => ({
            isServiceUp: response.isServiceUp,
            downtimeData: {
              id: response.downtimeData.id,
              service: response.downtimeData.service,
              message: response.downtimeData.message,
              status: response.downtimeData.status,
              dateFrom: new Date(response.downtimeData.dateFrom),
              dateTo: new Date(response.downtimeData.dateTo)
            }
          }))
    );
  }
}

import { NgModule } from '@angular/core';
import { LayoutComponent } from 'app/layout/layout.component';
import { SharedModule } from 'app/shared/shared.module';
import { EmptyLayoutComponent } from './layouts/empty/empty.component';
import { CenteredLayoutComponent } from './layouts/horizontal/centered/centered.component';
import { ThinLayoutComponent } from './layouts/vertical/thin/thin.component';
import { FuturisticLayoutComponent } from './layouts/vertical/futuristic/futuristic.component';
import { DenseLayoutComponent } from './layouts/vertical/dense/dense.component';
import { CompactLayoutComponent } from './layouts/vertical/compact/compact.component';
import { ClassyLayoutComponent } from './layouts/vertical/classy/classy.component';
import { ClassicLayoutComponent } from './layouts/vertical/classic/classic.component';
import { ModernLayoutComponent } from './layouts/horizontal/modern/modern.component';
import { EnterpriseLayoutComponent } from './layouts/horizontal/enterprise/enterprise.component';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseFullscreenModule } from '@fuse/components/fullscreen';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { MaterialLayoutComponent } from './layouts/horizontal/material/material.component';
import { QuickChatComponent } from './common/quick-chat/quick-chat.component';
import { FuseScrollbarModule } from '@fuse/directives/scrollbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { LanguagesComponent } from './common/languages/languages.component';
import { MessagesComponent } from './common/messages/messages.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotificationsComponent } from './common/notifications/notifications.component';
import { MatAutocompleteModule, MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { SearchComponent } from './common/search/search.component';
import { SettingsComponent } from './common/settings/settings.component';
import { ShortcutsComponent } from './common/shortcuts/shortcuts.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UserComponent } from './common/user/user.component';

@NgModule({
    declarations: [
        LayoutComponent,
        EmptyLayoutComponent,
        CenteredLayoutComponent,
        EnterpriseLayoutComponent,
        ModernLayoutComponent,
        MaterialLayoutComponent,
        ClassicLayoutComponent,
        ClassyLayoutComponent,
        CompactLayoutComponent,
        DenseLayoutComponent,
        FuturisticLayoutComponent,
        ThinLayoutComponent,
        QuickChatComponent,
        LanguagesComponent,
        MessagesComponent,
        NotificationsComponent,
        SearchComponent,
        SettingsComponent,
        UserComponent,
        ShortcutsComponent
    ],
    imports: [
        SharedModule,
        FuseLoadingBarModule,
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FuseFullscreenModule,
        FuseLoadingBarModule,
        FuseNavigationModule,
        FuseScrollbarModule,
        MatFormFieldModule,
        MatInputModule,
        FuseDrawerModule,
        OverlayModule,
        PortalModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        ReactiveFormsModule
    ],
    exports: [
        LayoutComponent,
        EmptyLayoutComponent,
        CenteredLayoutComponent,
        EnterpriseLayoutComponent,
        ModernLayoutComponent,
        MaterialLayoutComponent,
        ClassicLayoutComponent,
        ClassyLayoutComponent,
        CompactLayoutComponent,
        DenseLayoutComponent,
        FuturisticLayoutComponent,
        ThinLayoutComponent,
        QuickChatComponent,
        LanguagesComponent,
        MessagesComponent,
        NotificationsComponent,
        SearchComponent,
        SettingsComponent,
        UserComponent,
        ShortcutsComponent
    ],
    providers: [
        {
            provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => (): BlockScrollStrategy => overlay.scrollStrategies.block(),
            deps: [Overlay]
        }
    ]
})
export class LayoutModule {
}

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { COMMON_ENDPOINT } from 'app/core/constants/api-constant';
import { SupplierCategory } from 'app/interfaces/rfx-interface/rfx-common.interface';
import { SupplierTCList } from 'app/interfaces/rfx-interface/rfx-template.interface';
import { Observable } from 'rxjs';
import { CustomHttpService } from './../custom-http-service/custom-http.service';
// Observable<User[]>
@Injectable({
    providedIn: 'root'
})
export class CommonService {

    invokeTouchedMethod: any = () => { }

    constructor(private customHttp: CustomHttpService) { }

    fetchSupplierCategoryList(): Observable<SupplierCategory[]> {
        return this.customHttp.reactiveGet<SupplierCategory[]>(COMMON_ENDPOINT.SUPPLIER_CATEGORY_LIST)
    }

    fetchSupplierTcList(moduleName: string = 'rfx'): Observable<SupplierTCList[]> {
        const params = new HttpParams().set('module', moduleName)
        return this.customHttp.reactiveGet<SupplierTCList[]>(COMMON_ENDPOINT.SUPPLIER_TC_LIST, { params })
    }

}

import { Injectable } from '@angular/core';
import { COMMON_ENDPOINT, SUPPLIER } from 'app/core/constants/api-constant';
import { Supplier, SupplierProfileDisplayRes, SupplierProfileUpdatePayload } from 'app/interfaces/supplier';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomHttpService } from '../common/custom-http-service/custom-http.service';

@Injectable({
  providedIn: 'root',
})
export class SupplierProfileService {
  supplier = JSON.parse(sessionStorage.getItem('supplier'));
  refreshSupplier$ = new BehaviorSubject<Supplier>(this.supplier);

  constructor(private customService: CustomHttpService) {}

  getPaymentTerms() {
    return this.customService.reactiveGet(COMMON_ENDPOINT.GET_PAYMENT_TERMS);
  }

  getBankNames() {
    return this.customService.reactiveGet(COMMON_ENDPOINT.GET_BANK_NAMES);
  }

  getCurrencyNames() {
    return this.customService.reactiveGet(COMMON_ENDPOINT.CURRENCY_LIST_DROPDOWN);
  }

  firstUpdateProfile(profile: SupplierProfileUpdatePayload): Observable<any> {
    return this.customService.reactivePut<any>(SUPPLIER.FIRST_UPDATE_PROFILE, profile);
  }

  subsequentUpdateProfile(profile: SupplierProfileUpdatePayload): Observable<any> {
    return this.customService.reactivePut<any>(SUPPLIER.SUBSEQUENT_UPDATE, profile);
  }

  getProfile(): Observable<Supplier> {
    return this.customService.reactiveGet(SUPPLIER.GET_PROFILE);
  }

  getSupplierProfileDisplay(): Observable<SupplierProfileDisplayRes[]> {
    return this.customService.reactiveGet<SupplierProfileDisplayRes[]>(SUPPLIER.SUPPLIER_PROFILE_DISPLAY);
  }

  isUpdateRequired(): Observable<boolean>{
    return this.customService.reactiveGet<boolean>(SUPPLIER.ISUPDATE_REQUIRED);
  }
}

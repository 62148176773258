/* eslint-disable @typescript-eslint/naming-convention */
import { environment } from 'environments/environment';

export const GlobalVariable = Object.freeze({
    BASE_URL: environment.URL,
});

export const ApiUrls = Object.freeze({

    USER_LOGIN: `${GlobalVariable.BASE_URL}/user/user-login`,
    USER_DATA_PAYLOAD: `${GlobalVariable.BASE_URL}/user/user-data-payload`,
    USER_ROLE_BY_DEPT_ID: `${GlobalVariable.BASE_URL}/user/user-role-data`,

    // Supplier
    SUPPLIER_LIST: `${GlobalVariable.BASE_URL}/supplier/supplier-list`,
    EXPORT_SUPPLIERS_TEMPLATE: `${GlobalVariable.BASE_URL}/supplier/export-suppliers`,
    DOWNLOAD_TEMPLATE: `${GlobalVariable.BASE_URL}/supplier/export-supplier-template`,
    SINGLE_SUPPLIER: `${GlobalVariable.BASE_URL}/supplier/single-supplier`,
    UPLOAD_SUPPLIERS_LIST: `${GlobalVariable.BASE_URL}/supplier/upload-supplier`,
    UPDATE_SUPPLIER: `${GlobalVariable.BASE_URL}/supplier/update-supplier`,
    INDUSTRY_CATEGORY_LIST: `${GlobalVariable.BASE_URL}/supplier/industry-category-list`,

    //Supplier Contract PRice
    SUPPLIER_CONTRACT_PRICE_LIST: `${GlobalVariable.BASE_URL}/api/buyer/contract-price-supplier/list`,
    SUPPLIER_CONTRACT_PRICE_EXPORT: `${GlobalVariable.BASE_URL}/api/buyer/contract-price-supplier/contract-price-export`,
    SUPPLIER_CONTRACT_PRICE_PDF: `${GlobalVariable.BASE_URL}/api/buyer/contract-price-supplier/download-pdf`,
    SUPPLIER_PURCHASE_ORG: `${GlobalVariable.BASE_URL}/api/buyer/contract-price-supplier/active-purchase-orgs`,
    SUPPLIER_CONTRACT_PRICE_DETAIL: `${GlobalVariable.BASE_URL}/api/buyer/contract-price-supplier/supplier-detail`,

    // Supplier Role
    SUPPLIER_ROLE_LIST: `${GlobalVariable.BASE_URL}/supplier/supplier-role-list`,
    CREATE_SUPPLIER_ROLE: `${GlobalVariable.BASE_URL}/supplier/create-supplier-role`,
    UPDATE_SUPPLIER_ROLE: `${GlobalVariable.BASE_URL}/supplier/update-supplier-role`,
    SINGLE_SUPPLIER_ROLE: `${GlobalVariable.BASE_URL}/supplier/single-supplier-role`,
    EXPORT_SUPPLIER_ROLE: `${GlobalVariable.BASE_URL}/supplier/export-supplier-roles`,
    SUPPLIER_TAG_LIST: `${GlobalVariable.BASE_URL}/supplier/supplier-tags-list`,

    // Supplier-Module-Access-Rights
    SUPPLIER_MODULE_LIST: `${GlobalVariable.BASE_URL}/supplier/get-module-access-rights`,
    CREATE_SUPPLIER_MODULE: `${GlobalVariable.BASE_URL}/supplier/create-module-access-rights`,
    UPDATE_SUPPLIER_MODULE: `${GlobalVariable.BASE_URL}/supplier/update-module-access-rights`,
    SINGLE_SUPPLIER_MODULE: `${GlobalVariable.BASE_URL}/supplier/get-single-module-access-rights`,
    EXPORT_SUPPLIER_MODULE: `${GlobalVariable.BASE_URL}/supplier/export-supplier-module-access-rights`,

    // Supplier Invoice
    SUPPLIER_INVOICE_LIST: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/supplier-list`,
    // SUPPLIER_INVOICE_DETAILS: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/supplier-detail`,
    SUPPLIER_LIST_FILTER: `/api/buyer/Supplier3wayInvoice/supplier-list`,
    EXPORT_INVOICE_SUPPLIER: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/export-supplier3way-list`,
    DOWNLOAD_INVOICE_PDF: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/supplier/pdf/generate`,
    SUPPLIER_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/supplier/single-supplier`,
    UPDATE_INVOICE_STATUS: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/update-requested-invoice-status`,
    GET_INVOICE_SUMMARY: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/supplier-summary`,
    CREATE_INVOICE: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/create`,
    UPDATE_INVOICE: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/update`,
    GET_MORE_DO_LIST: `${GlobalVariable.BASE_URL}/api/buyer/gra-supplier/more-gra-list`,
    INVOICE_GET_MORE_DO_LIST: '/api/buyer/gra-supplier/invoice/more-gra-list',
    GET_GRABYDO: `${GlobalVariable.BASE_URL}/api/buyer/gra-supplier/gra-item-byDo`,
    SAP_INTEGRATION_INVOICE_CREATE_REQUEST: `/api/buyer/Supplier3wayInvoice/sap-integration-invoice-create-request`,
    GET_E_INVOICE_STATUS: `/api/buyer/supplier/e-invoice-status`,

    // User-Role
    USER_ROLE_LIST: `${GlobalVariable.BASE_URL}/user/user-role-list`,
    CREATE_USER_ROLE: `${GlobalVariable.BASE_URL}/user/create-user-role`,
    UPDATE_USER_ROLE: `${GlobalVariable.BASE_URL}/user/update-user-role`,
    SINGLE_USER_ROLE: `${GlobalVariable.BASE_URL}/user/single-user-role-list`,
    EXPORT_USER_ROLES: `${GlobalVariable.BASE_URL}/user/export-user-roles`,

    //User-List
    USER_LIST: `${GlobalVariable.BASE_URL}/user/user-list`,
    EXPORT_USERS: `${GlobalVariable.BASE_URL}/user/export-users`,
    UPDATE_USERS: `${GlobalVariable.BASE_URL}/user/update-user`,
    SINGLE_USER: `${GlobalVariable.BASE_URL}/user/get-user`,
    USER_DEPARTMENT: `${GlobalVariable.BASE_URL}/api/buyer/flat/departmentby-email`,

    //User-Module-Access-Rights
    USER_MODULE_LIST: `${GlobalVariable.BASE_URL}/user/get-module-access-rights`,
    CREATE_USER_MODULE: `${GlobalVariable.BASE_URL}/user/create-module-access-rights`,
    UPDATE_USER_MODULE: `${GlobalVariable.BASE_URL}/user/update-module-access-rights`,
    EXPORT_USER_MODULE: `${GlobalVariable.BASE_URL}/user/export-user-module-access-rights`,
    SINGLE_USER_MODULE: `${GlobalVariable.BASE_URL}/user/single-user-module-access-right`,

    // Department
    USER_DEPARTMENT_LIST: `${GlobalVariable.BASE_URL}/user/active-department-list`,
    DEPARTMENT_LIST: `${GlobalVariable.BASE_URL}/user/department-list`,
    DEPARTMENT_CREATE: `${GlobalVariable.BASE_URL}/user/create-department`,
    DEPARTMENT_UPDATE: `${GlobalVariable.BASE_URL}/user/update-department`,
    SINGLE_DEPARTMNET: `${GlobalVariable.BASE_URL}/user/get-department`,
    EXPORT_DEPARTMENT: `${GlobalVariable.BASE_URL}/user/export-department`,

    //User-Purchasing-Org-List
    USER_PURCHASING_LIST: `${GlobalVariable.BASE_URL}/user/purchasing-org-list`,
    SINGLE_PURCHASING_LIST: `${GlobalVariable.BASE_URL}/user/single-purchasing-org`,

    //User-Pre-Pr-List
    USER_PRE_PR_LIST: `${GlobalVariable.BASE_URL}/user/pre-pr-list`,
    SINGLE_PRE_PR_LIST: `${GlobalVariable.BASE_URL}/user/single-pre-pr`,

    // SINGLE_PRE_PR_LIST;

    //User-PR-List
    USER_PR_LIST: `${GlobalVariable.BASE_URL}/user/pr-list`,
    SINGLE_PR_LIST: `${GlobalVariable.BASE_URL}/user/single-pr`,

    //User-RFx-List
    USER_RFX_LIST: `${GlobalVariable.BASE_URL}/user/rfx-list`,
    SINGLE_RFX_LIST: `${GlobalVariable.BASE_URL}/user/single-rfx`,

    //User-Role-Based-On-Deparmenent
    USER_ROLE_BY_DEPT: `${GlobalVariable.BASE_URL}/user/user-role-data`,

    // PR Template
    PR_TEMPLATE_LIST: `${GlobalVariable.BASE_URL}/api/buyer/prTemplate/list`,
    POST_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/prTemplate/add`,
    EXPORT_PR_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/prTemplate/export-pr-template-list`,
    DOWNLOAD_PR_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/prTemplate/download-sourcing-template-format`,
    IMPORT_PR_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/prTemplate/import-sourcing-template`,
    PR_TEMPLATE_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/prTemplate/get-by-id`,

    // PR
    UPLOAD_BUYER_DOCS: `${GlobalVariable.BASE_URL}//api/buyer/documents/upload`,

    // Pre-PR Template
    PRE_PR_TEMPLATE_LIST: `${GlobalVariable.BASE_URL}/api/buyer/preprTemplate/list`,
    CREATE_PRE_PR_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/preprTemplate/create`,
    PRE_PR_TEMPLATE_UPDATE: `${GlobalVariable.BASE_URL}/api/buyer/preprTemplate`,
    PRE_PR_TEMPLATE_LIST_BY_USER: `${GlobalVariable.BASE_URL}/api/buyer/preprTemplate/listByUser`,
    PRE_PR_TEMPLATE_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/preprTemplate`,
    DOWNLOAD_PRE_PR_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/preprTemplate/export-prepr-template-format`,
    EXPORT_PRE_PR_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/preprTemplate/export-prepr-template-by-id`,
    IMPORT_PRE_PR_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/preprTemplate/import-prepr-template`,
    USER_PRE_PR_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/preprTemplate/user-preprTemplatelist`,
    UPLOAD_PRE_PR_TEMPLATE_LIST: `${GlobalVariable.BASE_URL}/api/supplier/upload-supplier`,
    DEPARTMENT_LIST_BY_PURCHASING_ID: `${GlobalVariable.BASE_URL}/api/buyer/flat/departmentby-purchaseorg`,


    // PrePR
    PRE_PR_GET_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/prePr`,
    PRE_PR_CREATE: `${GlobalVariable.BASE_URL}/api/buyer/prePr/create`,
    PRE_PR_UPDATE: `${GlobalVariable.BASE_URL}/api/buyer/prePr`,
    PREPR_LIST: `${GlobalVariable.BASE_URL}/api/buyer/prePr/listByUser`,
    SAMPLE_PRE_PR_BOQ: `${GlobalVariable.BASE_URL}/api/buyer/prePr/sample-boq`,
    EXPORT_PRE_PR_BOQ: `${GlobalVariable.BASE_URL}/api/buyer/prePr/export-boq`,
    UPLOAD_PRE_PR_BOQ: `${GlobalVariable.BASE_URL}/api/buyer/prePr/upload-boq`,
    EXPORT_PREPR_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/prePr/export-prepr-by-id`,
    UPLOAD_PRE_PR_SUPPORTING_DOCS: `${GlobalVariable.BASE_URL}/api/buyer/file/supporting-docs`,
    UPDATE_PRE_PR_SUPPORTING_DOCS: `${GlobalVariable.BASE_URL}/api/buyer/prePr/supportingdocs`,
    DELETE_PRE_PR_SUPPORTING_DOCS: `${GlobalVariable.BASE_URL}/api/buyer/prePr/deleteSupportingDocs`,
    PRE_PR_APPROVE_STATUS_UPDATE: `${GlobalVariable.BASE_URL}/api/buyer/prePr/updateStatus`,
    PRE_PR_APPROVE: `${GlobalVariable.BASE_URL}/api/buyer/prePr/approve-prepr`,
    PRE_PR_REJECT: `${GlobalVariable.BASE_URL}/api/buyer/prePr/reject-prepr`,
    PRE_PR_ESCALATE: `${GlobalVariable.BASE_URL}/api/buyer/prePr/escalate-prepr`,
    DOWNLOAD_PRE_PR_PDF: `${GlobalVariable.BASE_URL}/api/buyer/prePr/pre_pr_summary`,
    COST_CENTER_LIST_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/flat/cost-center-list-by-purchaseQrg`,
    PREPR_COPY_LIST: `${GlobalVariable.BASE_URL}/api/buyer/prePr/prepr-copyList`,
    EXPORT_PREPR_COPY: `${GlobalVariable.BASE_URL}/api/buyer/prePr/export-prepr-copy`,

    // PaymentRemittance
    PAY_REM_LIST: `/api/buyer/payment-remittance-supplier/buyer-list`,
    PAY_REM_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/payment-remittance-supplier/buyer`,
    EXPORT_PAY_REM: `${GlobalVariable.BASE_URL}/api/buyer/payment-remittance-supplier/buyer/export-list`,
    PAY_REM_PURCHASE_ORG: `${GlobalVariable.BASE_URL}/api/buyer/payment-remittance-supplier/purchase-org-list`,
    PAY_REM_PDF_DOWNLOAD: `${GlobalVariable.BASE_URL}/api/buyer/payment-remittance-supplier/buyer/summary`,

    // PaymentRemittanceSupplier
    PAY_REM_SUPPLIER_LIST: `/api/buyer/payment-remittance-supplier/supplier-list`,
    PAY_REM_SUPPLIER_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/payment-remittance-supplier/supplier`,
    EXPORT_PAY_REM_SUPPLIER: `${GlobalVariable.BASE_URL}/api/buyer/payment-remittance-supplier/supplier/export-list`,
    PAY_REM_SUPLIER_PDF_DOWNLOAD: `${GlobalVariable.BASE_URL}/api/buyer/payment-remittance-supplier/supplier/summary`,

    //GRA
    GRA_LIST: `/api/buyer/gra-supplier/list`,
    GRA_DETAIL: `${GlobalVariable.BASE_URL}/api/buyer/gra-supplier`,
    GRA_ITEMLIST: '/api/buyer/gra-supplier/gra-itemlist',
    EXPORT_GRA: `${GlobalVariable.BASE_URL}/api/buyer/gra-supplier/export-list`,
    GRA_PDF_DOWNLOAD: `${GlobalVariable.BASE_URL}/api/buyer/gra-supplier/summary`,

    //PIR
    PIR_LIST: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/buyers-list`,
    EXPORT_PIR: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/export-buyers3way-list`,
    PIR_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/buyers-detail`,

    // Masters
    PRE_PR_STATUS_LIST: `${GlobalVariable.BASE_URL}/api/buyer/prePr/status-list`,
    COST_CENTER_LIST: `${GlobalVariable.BASE_URL}/api/buyer/flat/cost-center-list`,
    CURRENCY_LIST: `${GlobalVariable.BASE_URL}/api/buyer/flat/currency-list`,
    DELIVERY_ADDRESS_LIST: `${GlobalVariable.BASE_URL}/api/buyer/flat/address-list`,
    DEPARTMENTOPTION_LIST: `${GlobalVariable.BASE_URL}/api/buyer/flat/department-list`,
    DEPARTMEN_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/flat/department`,
    WARRANTY_LIST: `${GlobalVariable.BASE_URL}/api/buyer/flat/warranty-list`,
    FLAT_SUPPLIER_LIST: `${GlobalVariable.BASE_URL}/api/buyer/flat/supplier-list`,
    UOM_LIST: `${GlobalVariable.BASE_URL}/api/buyer/flat/uom-list`,
    INTERNAL_ORDER_LIST: `${GlobalVariable.BASE_URL}/api/buyer/flat/internalorder-list`,
    PART_NUMBER_LIST: `${GlobalVariable.BASE_URL}/api/buyer/flat/part-number-list`,
    ALL_USER_LIST: `${GlobalVariable.BASE_URL}/api/buyer/user/allUserList`,

    //PO
    PO_LIST_FOR_SUPPLIER: `${GlobalVariable.BASE_URL}/api/buyer/po/po-list-for-supplier`,
    PO_LIST_FOR_SUPPLIER_EXPORT_EXCEL: `${GlobalVariable.BASE_URL}/api/buyer/po/po-list-for-supplier/exportExcel`,
    PO_DETAILS_FOR_SUPPLIER: `${GlobalVariable.BASE_URL}/api/buyer/po/byPONumber/{0}`,
    PO_PURCHASE_ORG_LIST: `${GlobalVariable.BASE_URL}/api/buyer/master/purchase-org-list-drop`,
    PO_PURCHASE_ORG_MASTER_LIST: `${GlobalVariable.BASE_URL}/api/buyer/master/purchase-org-list`,
    PO_DETAILS_FOR_SUPPLIER_SUMMARY_PDF: `${GlobalVariable.BASE_URL}/api/buyer/po/summaryPDF/{0}`,
    PO_DETAILS_FOR_SUPPLIER_DETAILS_PDF: `${GlobalVariable.BASE_URL}/api/buyer/po/detailsPDF/{0}`,
    PO_DETAILS_GRA_BY_PO_NUM: `${GlobalVariable.BASE_URL}/api/buyer/gra-supplier/by-ponumber/{0}`,
    PO_DETAILS_INVOICE_BY_PO_NUM: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/invoice-list-by-po-number/{0}`,
    PO_AUDIT_HISTORY_BY_PO_NUM: `${GlobalVariable.BASE_URL}/api/buyer/po/auditHistory/{0}`,
    PO_ACCEPT: `${GlobalVariable.BASE_URL}/api/buyer/po/accept/{0}`,
    PO_DECLINE: `${GlobalVariable.BASE_URL}/api/buyer/po/decline/{0}`,
    PO_TERMS_AND_CONDITIONS: `${GlobalVariable.BASE_URL}/api/buyer/po/genericPODocuments`,

    //DOWNTIME MESSAGE
    DOWNTIME_MESSAGE: `${GlobalVariable.BASE_URL}/api/buyer/downtime-message/check-service-downtime-status`,
});


<!-- <div id="loader-wrapper">
  <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
  </div>
</div> -->


<div class="fuse-splash-screen">
  <img
      src="assets/images/logo/logo.svg"
      alt="Fuse logo">
  <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
  </div>
  <p *ngIf="msg">{{msg}}</p>
</div>

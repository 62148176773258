import { AfterViewInit, Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { CustomFieldErrorMatcher } from 'app/helper/custom-validators';

@Component({
  selector: 'app-non-decimal-input-field',
  templateUrl: './non-decimal-input-field.component.html',
  styleUrls: ['./non-decimal-input-field.component.scss']
})
export class NonDecimalInputFieldComponent implements ControlValueAccessor, Validators, AfterViewInit {
  @Input() fieldLabel: string
  @Input() placeholder: string

  @Output() changeEvent = new EventEmitter<string | number>()


  stateMatcher: ErrorStateMatcher

  initialValue: string | number = ''
  isFieldRequird: boolean
  isFieldDisabled: boolean = false

  constructor(@Self() @Optional() public control: NgControl) {
    this.control && (this.control.valueAccessor = this);
  }

  onChange = (value: string | number) => { };
  onTouched = () => { };

  inputHandler(inputValue: string) {
    this.initialValue = inputValue
    this.onChange(this.initialValue)
  }

  writeValue(value: string | number): void {
    this.initialValue = value
  }

  registerOnChange(fn: any): void { this.onChange = fn }
  registerOnTouched(fn: any): void { this.onTouched = fn }
  setDisabledState?(isDisabled: boolean): void { this.isFieldDisabled = isDisabled }

  ngAfterViewInit(): void {
    const timeId = setTimeout(() => {
      if (this.control) {
        this.stateMatcher = new CustomFieldErrorMatcher(this.control.control);
        this.isFieldRequird = this.control?.control?.hasValidator(Validators.required)
        clearTimeout(timeId)
      }
    })
  }

}

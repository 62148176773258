import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputFieldComponent } from './input-field/input-field.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { ReadMoreComponent } from './readmore/readmore.component';
// import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { TimePickerComponent } from './time-picker/time-picker.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';

import { QuestionnaireFormComponent } from './shared-forms/questionnaire-form/questionnaire-form.component';
import { QestionFormComponent } from './shared-forms/questionnaire-form/qestion-form/qestion-form.component';
import { QSectionFormComponent } from './shared-forms/questionnaire-form/q-section-form/q-section-form.component';
import { TeamMemberFormComponent } from './shared-forms/team-member-form/team-member-form.component';
import { TeamMemberViewerTypeComponent } from './shared-forms/team-member-form/team-member-viewer-type/team-member-viewer-type.component'
import { MaterialModule } from '../material/material.module';
import { NonDecimalInputFieldComponent } from './non-decimal-input-field/non-decimal-input-field.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { TypeofModule } from 'pipes/typeof/typeof.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

const components = [
    InputFieldComponent,
    TextAreaComponent,
    ErrorMessageComponent,
    DropdownComponent,
    ReadMoreComponent,
    DatePickerComponent,
    TimePickerComponent,
    CheckboxComponent,
    MultiSelectDropdownComponent,
    QuestionnaireFormComponent,
    QestionFormComponent,
    QSectionFormComponent,
    TeamMemberFormComponent,
    NonDecimalInputFieldComponent,
    ProgressBarComponent
]

@NgModule({
    declarations: [
        ...components,
        TeamMemberViewerTypeComponent,
        ProgressBarComponent,

    ],
    imports: [
        CommonModule,
        NgxMatSelectSearchModule,
        // NgxMaterialTimepickerModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        TypeofModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        ...components
    ]
})
export class FormFieldsModule { }

import { GlobalVariable } from "app/config";

export const AUTH_ENDPOINT = {
  USER_LOGIN: '/api/buyer/supplier/login',
  FORGOT_PASSWORD: '/api/buyer/supplier/forgot-password',
  VALIDATE_PASSWORD_TOKEN: '/api/buyer/supplier/validate-token',
  GET_PASSWORD_VALIDATION_SETTING: '/api/buyer/supplier/password-setting',
  RESET_NEW_PASSWORD: '/api/buyer/supplier/reset-new-password',
  RESET_PASSWORD: '/api/auth/reset-password',
  REFRESH_ACCESS_TOKEN: '/api/auth/refresh-access-token',
  SIGN_UP: '/api/auth/sign-up',
  UNLOCK_SESSION: '/api/auth/unlock-session',
};

export const COMMON_ENDPOINT = {
  SUPPLIER_CATEGORY_LIST: '/api/buyer/supplier/supplier-cat-list',
  ALL_USER_LIST: '/api/buyer/user/allUserList',
  SUPPLIER_TC_LIST: '/api/buyer/documents/suppliers-terms-cond-list',
  // PURCHASE_ORG_LIST: '/api/buyer/master/purchase-org-list',
  PURCHASE_ORG_LIST_DROP: '/api/buyer/master/purchase-org-list-drop',
  GET_PAYMENT_TERMS: '/api/buyer/master/paymentterms',
  GET_BANK_NAMES: '/api/buyer/master/banks',
  CURRENCY_LIST_DROPDOWN: '/api/buyer/master/currency-list-dropdown',
  ROLE_BY_SUPPLIER: '/api/buyer/supplier/role-by-supplier'
};

export const USER = {
  USERS_BY_DEPARTMENT: '/user/userListByDepartmentId', // departmentId
  USER_DATA_PAYLOAD: '/user/user-data-payload',
  USER_LIST: '/user/user-list',
};

export const RFX_TEMPLATE = {
  CREATE_BUYER_RFX_TEMPLATE: '/api/buyer/rfx-template/add',
  GET_BUYER_RFX_TEMPLATE_BY_ID: '/api/buyer/rfx-template/get-by-id', // pass id in query param
  GET_BUYER_RFX_TEMPLATE: '/api/buyer/rfx-template/list',
  UPDATE_BUYER_RFX_TEMPLATE: '/api/buyer/rfx-template/update',
  DOWNLOAD_RFX_TEMPLATE: '/api/buyer/rfx-template/download_rfx-template',
  EXPORT_RFX_TEMPLATE: '/api/buyer/rfx-template/export-rfx-template',
  IMPORT_RFX_TEMPLATE: '/api/buyer/rfx-template/import-rfx-template',
  RFX_DROPDOWN_TEMPLATE: '/api/buyer/rfx-template/dropdown',
};

export const RFX_FORM = {
  CREATE_RFX: '/api/buyer/rfx-form/create',
  RFX_LIST: '/api/buyer/rfx-form/list',
  RFX_BY_ID: '/api/buyer/rfx-form/rfx',
  PRE_PR_AND_RFX_METADATA: '/api/buyer/rfx-form/rfx/data', // {templateId}/{pprId}
  RFX_LIST_SEARCH: '/api/buyer/rfx-form/rfx/list/search',
  RFX_LIST_EXPORT: '/api/buyer/rfx-form/export',
  UPDATE_RFX_FORM: '/api/buyer/rfx-form/update', // pass id in query param
  DELETE_BUYER_DOCS: '/api/buyer/documents/file/delete',
  SUBMIT_FOR_APPROVAL: '/api/buyer/rfx-form/submit-for-approval',
  CHANGE_APPROVAL_STATUS: '/api/buyer/rfx-form/approval/change-status',
  RFX_APPROVAL_HISTORY: '/api/buyer/rfx-form/approval/list-history',
  UPDATE_APPROVAL_ROUTE: '/api/buyer/rfx-form/approval/update-route',
  REJECT_PRE_PR: '/api/buyer/rfx-form/approval/reject-pre-pr',
  DOWNLOAD_RFX_SUMMARY: '/api/buyer/prePr/rfx_summary',
};

export const MASTER_SUPPORTING_DOC = {
  SUPPORTING_DOC_LIST: '/api/buyer/documents/supporting-doc-list',
  SUPPORTING_DOCS: '/api/buyer/documents/supporting-docs',
  SUPPORTING_DOCS_BY_ID: '/api/buyer/documents/supportingdocs',
};

export const MASTER_SUPPLIER_CATEGORY = {
  SUPPLIER_CATEGORY_LIST: '/api/buyer/suppler/supplier-category-list',
  SUPPLIER_CATEGORY_BY_ID: '/api/buyer/suppler/category-supplier-list',
  SUPPLIER_CATEGORY_CREATE: '/api/buyer/suppler/create-category-supplier',
  SUPPLIER_CATEGORY_UPDATE: '/api/buyer/suppler/supplier-category',
  SUPPLIER_CATEGORY_DROPDOWN: '/api/buyer/suppler/suppliersCategoryDrop',
  SUPPLIER_DRIPDOWN: '/api/buyer/suppler/suppliersDrop',
  SUPPLIER_BY_CATEGORY_ID: '/api/buyer/suppler/supplierslist',
};

export const SUPPLIER = {
  SUPLLIER_INVITATION: '/api/buyer/supplier/invitation',
  SUPLLIER_INVITATION_ACTION: '/api/buyer/supplier/invitation/action',
  SUPLLIER_EVENT_LIST: '/api/buyer/supplier/event-list',
  SUPLLIER_SOURCING_LIST: '/api/buyer/supplier/sourcing-list',
  RFX_BY_ID: '/api/buyer/supplier/rfx',
  ACTIVE_EVENT_COUNT: '/api/buyer/supplier/active-event-count',
  ACCEPT_TNC: '/api/buyer/supplier/accept-tnc',
  PROFILE_STATUS: '/api/buyer/supplier/profile-status',
  FIRST_UPDATE_PROFILE: '/api/buyer/supplier/profile/first-update',
  SUBSEQUENT_UPDATE: '/api/buyer/supplier/profile/subsequent-update',
  GET_PROFILE: '/api/buyer/supplier/profile',
  SUPPLIER_MODULE_LISTING: '/api/buyer/supplier/access-rights',
  SUPPLIER_PROFILE_DISPLAY: '/api/buyer/supplier/profile-display',
  ISUPDATE_REQUIRED: '/api/buyer/supplier/profile/isupdate-required',
};

export const SUPPLIER_USER = {
  SUPPLIER_USER: '/api/buyer/supplier/supplier-user',
  SUPPLIER_USER_LIST: '/api/buyer/supplier/supplier-user-list',
  EXPORT_SUPPLIER_USER: `${GlobalVariable.BASE_URL}/api/buyer/supplier/export-supplier-user`
}

export const SUPPLIER_RESPONSE = {
  SUPPLIER_RESPONSE_CREATE: '/api/buyer/supplier-response/create',
  SUPPLIER_RESPONSE_GET_ONE: '/api/buyer/supplier-response/get-one',
  SUPPLIER_RESPONSE_UPDATE_ONE: '/api/buyer/supplier-response/update-one',
  SUPPLIER_RESPONSE_SUBMIT: '/api/buyer/supplier-response/submit',
  SUPPLIER_RESPONSE_DOWNLOAD_BOQ_EXCEL:
    '/api/buyer/supplier-response/export-rfx-supplier-response-boq',
  SUPPLIER_RESPONSE_UPLOAD_BOQ: '/api/buyer/rfx-form/upload-supplier-boq',
  SUPPLIER_UPDATE_ATTENDANCE:
    '/api/buyer/supplier-response/supplier/update-attendance',
  SUPPLIER_SEND_MESSAGE:
    '/api/buyer/supplier-response/supplier/send-one-message',
  SUPPLIER_RESPONSE_CHECK_IF_RESPONDED:
    '/api/buyer/supplier-response/check-if-responded',
  GET_SUPPLIER_RESPONSE_MESSAGES:
    '/api/buyer/supplier-response/get-supplier-response-messages',
  GET_MESSAGE_HISTORY: '/api/buyer/supplier-response/get-message-history',
};

export const DOCUMENTS = {
  DOCUMENT_UPLOAD: '/api/buyer/documents/upload',
  MULTIPLE_DOCUMENT_UPLOAD: '/api/buyer/documents/multi/upload',
  DOCUMENT_DELETE: '/api/buyer/documents/file/delete',
  DOCUMENT_DOWNLOAD: '/api/buyer/documents/file/download',
};

export const SUPPORTING_DOCUMENT = {
  UPLOAD_DOC: '/api/buyer/file/supporting-docs',
  UPLOAD_EXCEL: '/api/buyer/file/upload-excel',
  EXPORT_EXCEL: '/api/buyer/file/export-excel',
};

export const SUPPLIER_2WAY_INVOICE = {
  CREATE_INVOICE: '/api/buyer/supplier2way-invoice/create', // ?poId=532
  GET_INVOICE: '/api/buyer/Supplier3wayInvoice/supplier-summary',
  DELETE_INVOICE_ITEM: '/api/buyer/supplier2way-invoice/invoice/item',
  UPDATE_INVOICE: '/api/buyer/supplier2way-invoice/update',
  FINAL_INVOICE: '/api/buyer/supplier2way-invoice/invoice/final',
  CANCEL_INVOICE: '/api/buyer/supplier2way-invoice/cancel',
  INVOICE_REVIEW_BACK: '/api/buyer/supplier2way-invoice/review-back',
  SUBMIT_FOR_APPROVAL: '/api/buyer/supplier2way-invoice/submit-for-approval'
}

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    constructor(
        private matSnackBar: MatSnackBar,
    ) { }

    isNullOrEmpty(value: string | undefined | null): boolean {
        return (value == null || value == undefined || this.isEmpty(value));
    }

    isEmpty(value: string): boolean {
        return (!value || value.trim() === '' || (value.trim()).length === 0);
    }

    showSuccessSnack(message: string): void {
        this.matSnackBar.open(message, undefined, {
            duration: 5000,
            panelClass: ['green-snackbar'],
            horizontalPosition: 'center',
            verticalPosition: 'top',
        }
        );
    }

    showErrorSnack(message: string, duration: number = 5000): void {
        this.matSnackBar.open(message, undefined, {
            duration: duration,
            panelClass: ['red-snackbar'],
            horizontalPosition: 'center',
            verticalPosition: 'top',
        }
        );
    }

    public getEnumForDropDown(enums: any): any {
        const newObjects: Array<any> = [];
        const enumKeys = Object.keys(enums);
        enumKeys.forEach((data) => {
            const newObject: any = {};
            newObject.id = data;
            newObject.name = enums[data];
            newObjects.push(newObject);
        });
        return newObjects;
    }

    formatString(str: string, ...val: string[]) {
        for (let index = 0; index < val.length; index++) {
            str = str.replace(`{${index}}`, val[index]);
        }
        return str;
    }
}

<ng-container>
    <div *ngFor="let questionnaire of questionnaireList; let parentIndex = index" class="mb-2">
        <div class="md:flex md:flex-row">
            <mat-label class="text-gray-900 font-medium mt-3 pr-5">
                Questionnaire Set Name
            </mat-label>

            <div class="md:w-4/12 w-full">
                <mat-form-field class="fuse-mat-dense w-full" floatLabel="always">
                    <input matInput placeholder="Enter set name" [(ngModel)]="questionnaire.setName"
                        (blur)="onTouched()" [required]="true" [maxlength]="150" />
                </mat-form-field>
            </div>
            <div class="pl-5">
                <button mat-icon-button (click)="removeQuestionnaierSet(parentIndex)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
        <div class="flex items-center py-3">
            <button [disabled]="isFieldDisabled" class="mat-primary rounded"
                [ngClass]="{'bg-red-500' : displayError && !questionnaire.sections.length }" mat-raised-button
                (click)="sectionPopup(ADD_OR_UPDATE_ENUM.ADD, parentIndex, true)">
                <span *ngIf="displayError && !questionnaire.sections.length" class="mr-1">
                    Click Here To </span> Add Section
            </button>

        </div>
        <div class="table-box1 pb-3" *ngIf="questionnaire.sections.length">
            <table>
                <tr>
                    <th></th>
                    <th>Action</th>
                    <th>No.</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Question Type</th>
                </tr>

                <ng-container *ngFor="let section of questionnaire.sections; let childIndex = index">
                    <tr>
                        <td>
                            <mat-checkbox></mat-checkbox>
                        </td>
                        <td>
                            <button mat-icon-button (click)="editSection(parentIndex, childIndex)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button (click)="removeSection(parentIndex, childIndex)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                        <td>{{ section.sNo }}</td>
                        <td>{{ section.name | titlecase }}</td>
                        <td>{{ section.description }}</td>
                        <td>
                            <button class="mat-primary ml-4 rounded bg-[#00AFFA]" mat-raised-button
                                (click)="questionPopup(ADD_OR_UPDATE_ENUM.ADD, parentIndex, childIndex)">
                                Add Question
                            </button>
                        </td>
                    </tr>
                    <ng-container *ngIf="section">
                        <tr *ngFor="let question of section.questions; let qIndex = index" class="bg-sky-100">
                            <td>
                                <mat-checkbox></mat-checkbox>
                            </td>
                            <td>
                                <button mat-icon-button (click)="editQuestion(parentIndex, childIndex, qIndex)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button (click)="removeQuestion(parentIndex, childIndex, qIndex)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                            <td>{{ section.sNo + " . " + (qIndex + 1) }}</td>
                            <td>{{ question.question | titlecase}}</td>
                            <td>{{ question.answerType | titlecase }}</td>
                            <td></td>
                        </tr>
                    </ng-container>
                </ng-container>
            </table>
        </div>
    </div>
</ng-container>
<button
    *ngIf="(questionnaireList[0].setName && questionnaireList[0].sections.length) && enableAddMoreButton"
    class="mat-primary my-4 rounded" mat-raised-button (click)="addQuestionnaireSet()">
    Add More Questionnaire Set
</button>
import { API_TIMEOUT } from '../../../constants/app-constant';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MultilineErrorSnackComponent } from 'app/shared/multiline-error-snack/multiline-error-snack.component';


interface HttpOptions {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class CustomHttpService {

    constructor(
        private http: HttpClient,
        private matSnackBar: MatSnackBar,
    ) { }

    /**
     * @param url     The endpoint URL.
     * @param options The HTTP options to send with the request.
     *
     * @return An `Observable` of the `HttpResponse`, with a response body in the requested type.
     */
    reactiveGet<T>(endPoint: string, options?: HttpOptions): Observable<T> {
        return this.http
            .get<T>(environment.URL + endPoint, options)
            .pipe(timeout(API_TIMEOUT), catchError(this.handleError));
    }

    /**
     * @param url The endpoint URL.
     * @param body The content to replace with.
     * @param options HTTP options
     *
     * @return  An `Observable` of the `HttpResponse` for the request, with a response body in the
     * requested type.
     */
    reactivePost<T>(endPoint: string, body: any, options?: HttpOptions): Observable<T> {
        return this.http
            .post<T>(environment.URL + endPoint, body, options)
            .pipe(timeout(API_TIMEOUT), catchError(this.handleError));
    }

    /**
    * @param url The endpoint URL.
    * @param body The resources to add/update.
    * @param options HTTP options
    *
    * @return An `Observable` of the requested type.
    */
    reactivePut<T>(endPoint: string, body: any, options?: HttpOptions): Observable<T> {
        return this.http
            .put<T>(environment.URL + endPoint, body, options)
            .pipe(timeout(API_TIMEOUT), catchError(this.handleError));
    }

    /**
     * @param url The endpoint URL.
     * @param body The resources to edit.
     * @param options HTTP options.
     *
     * @return  An `Observable` of the `HttpResponse` for the request,
     * with a response body in the given type.
     */
    reactivePatch<T>(endPoint: string, body: any, options?: HttpOptions): Observable<T> {
        return this.http
            .patch<T>(environment.URL + endPoint, body, options)
            .pipe(timeout(API_TIMEOUT), catchError(this.handleError));
    }

    /**
     * @param url     The endpoint URL.
     * @param options The HTTP options to send with the request.
     *
     * @return An `Observable` of the `HttpResponse`, with response body in the requested type.
     */
    reactiveDelete<T>(endPoint: string, options?: HttpOptions): Observable<T> {
        return this.http
            .delete<T>(environment.URL + endPoint, options)
            .pipe(timeout(API_TIMEOUT), catchError(this.handleError));
    }


    private handleError = (err: any): Observable<never> => {
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            // this.showErrorSnack(err.error.message[0])
            // errorMessage = `Backend returned code ${err.status}`;
            if(err.error.message && err.error.message.length && err.status){
                err.error.message.length > 1
                ? this.showMultilineErrorSnack(err.error.message) : this.showErrorSnack(err.error.message[0]);
                errorMessage = `Backend returned code ${err.status}`;
            }else{
                this.showErrorSnack('something went wrong!!!')
            }
        }
        // this.showErrorSnack('something went wrong')
        return throwError(errorMessage);
    };

    getSnackConfig(): any {
      return {
        duration: 5000,
        panelClass: ['red-snackbar'],
        horizontalPosition: 'center',
        verticalPosition: 'top',
      };
    }

    showMultilineErrorSnack(message: string[]): void {
      this.matSnackBar.openFromComponent(MultilineErrorSnackComponent, {
        ...this.getSnackConfig(),
        data: message
      });
    }

    showErrorSnack(message: string): void {
        if(message === 'Unauthorized'){
            return;
        }
        this.matSnackBar.open(message, undefined, this.getSnackConfig());
    }
}

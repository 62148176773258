import { MODULE_ACCESS_NAMES } from "app/enums/common-enums/common-enum";
import { EventType } from "app/interfaces/common-interface/common-interface";
import { Menu } from "app/interfaces/common-interface/menu";
import { dropdown } from "app/interfaces/shared-form-field-interface/dropdown.interface";
import { Supplier } from "app/interfaces/supplier";
import { ParentRight } from "app/interfaces/supplier-access-right-interface/supplier-access-right.interface";
import moment from "moment";

export function generateArrayFromEnum(enumValue: any, onlyName: boolean = false): dropdown[] {
    const value: dropdown[] = []; // string all enum value in this array
    let index = 0;
    for (let i in enumValue) {
      index++;
      onlyName ? value.push({ name: enumValue[i] }) : value.push({ id: index, name: enumValue[i] });
    }
    return value;
}

export function parseUTCDateToLocal(dateStr: string): Date {
  // return new Date(dateStr + ' UTC');
  let date = new Date(dateStr);
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}

// export function formatDateTime(dateTime): string | any {
//     if (!dateTime) return;
//     const date = new Date(dateTime);
//     const filterdTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
//     return `${date.toLocaleDateString('en-US')} ${filterdTime}`;

// }

export function formatDateTime(
  utc: any,
  displayTime?: string,
  showOnlyTime?: string
) {
  /**
   * @param utc
   * @param displayTime if you want to display time along with date than pass this (displayTime) args
   * @param shoOnlyTime if you want to display only time than pass (showOnlyTime) this args
   */
  if (!showOnlyTime) {
    if (displayTime) {
      return moment(utc).format(`DD/MM/YYYY ${displayTime ? 'h: mm a' : ''}`);
    }
    else return moment(utc).format('DD/MM/YYYY');
  } else return moment(utc).format('h: mm a');
}

export function curentUserInfo(): Supplier {
  return JSON.parse(sessionStorage.getItem('t-pro'))?.supplier;
}
export function hasUserLogin(): boolean {
  if (sessionStorage.getItem('t-pro') === null) {
    return false;
  }
  return true;
}
// export function curentLoggedInUserInfo(): User {
//   return JSON.parse(localStorage.getItem('t-pro')).user;
// }

export function removeNullIdFromObject(obj: {}, id: string): void {
  for (let key in obj) {
    switch (typeof obj[key]) {
      case 'object':
        if (id.indexOf(key) > -1) delete obj[key];
        else removeNullIdFromObject(obj[key], id);
        break;
      default:
        if (id.indexOf(key) > -1 && !obj[key]) delete obj[key];
        break;
    }
  }
}

export function removeKeyFromObject(obj: {}, id: string): void {
  for (let key in obj) {
    switch (typeof obj[key]) {
      case 'object':
        if (id.indexOf(key) > -1) delete obj[key];
        else removeKeyFromObject(obj[key], id);
        break;
      default:
        if (id.indexOf(key) > -1) delete obj[key];
        break;
    }
  }
}

export function getEventTypesAbbr(
  eventTypes: EventType[],
  eventName: string
): string {
  if (eventName && eventName !== '') {
    const event = eventTypes.find(
      (item) =>
        item.event_type_description.toLowerCase() === eventName.toLowerCase()
    );
    return event ? event.event_type_name : '--';
  }
  return '--';
}

const getArrayFromString = (value: string, seperator: string): string[] =>
  value && value !== '' ? value.split(seperator) : [];
export default getArrayFromString;

export function numberWithCommas(x) {
  if (x <= 0) return x;
  return x.toString().split('.')[0].length > 3
    ? x
        .toString()
        .substring(0, x.toString().split('.')[0].length - 3)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
        ',' +
        x.toString().substring(x.toString().split('.')[0].length - 3)
    : x.toString();
}

export function continentalTimeToAmPM(time: string) {
  if (!time) {
    return '';
  }

  const [hour, min] = time.split(':');

  if (parseInt(hour) >= 12) {
    const convertedTime = parseInt(hour) - 12;
    const localTime = `${convertedTime === 0 ? 12 : convertedTime}:${min} PM`;
    return localTime;
  } else return time + ' AM';
}

export function getLoggedUserAccess(): ParentRight[] {
  return JSON.parse(sessionStorage.getItem('user-access'));
}

export function hasAccess(moduleAccessName: MODULE_ACCESS_NAMES, ...rightName: string[]): boolean {
  const moduleAccess = getLoggedUserAccess()?.find(item => item.name === moduleAccessName);
  const childRight = moduleAccess?.childRights?.find(item2 => rightName.includes(item2.name));
  return childRight ? true : false;
}

export function getMenu(): Menu[] {
  const moduleAccess:ParentRight[] = getLoggedUserAccess();

  const menu: Menu[] = [];
  menu.push({
    id: 'navigation-features',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/dashboard',
    sequence: 1
  });

  moduleAccess?.forEach(right => {
    if (right.name !== MODULE_ACCESS_NAMES.SUPPLIER_PROFILE
      && right.name !== MODULE_ACCESS_NAMES.ANNOUNCEMENT) {
      const menuItem: Menu = {
        id: `${right.id}`,
        title: right.name,
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: ''
      };
      setMenuIconLink(menuItem);
      menu.push(menuItem);
    }
  });

  menu.push({
    id: 'dashboard.pir',
    title: 'User Guide',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/user-guide',
    sequence: 9
  });

  return menu.sort((a, b) => { return a.sequence - b.sequence});
}

function setMenuIconLink(menu: Menu): void {
  menu.icon = 'heroicons_outline:chart-pie';

  switch (menu.title) {
    case MODULE_ACCESS_NAMES.EVENTS:
      menu.link = '/sourcing';
      menu.sequence = 2;
      break;
    case MODULE_ACCESS_NAMES.PURCHASE_ORDER:
      menu.link = '/po';
      menu.sequence = 3;
      break;
    case MODULE_ACCESS_NAMES.GOOD_RECEIPT_ADVICE:
      menu.link = '/gra';
      menu.sequence = 4;
      break;
    case MODULE_ACCESS_NAMES.INVOICE:
      menu.link = '/invoice';
      menu.sequence = 5;
      break;
    case MODULE_ACCESS_NAMES.PAYMENT_REMITTANCE:
      menu.link = '/payment-remittance-supplier';
      menu.sequence = 6;
      break;
    case MODULE_ACCESS_NAMES.SUPPLIER_FORM:
      menu.link = '/supplier-form';
      menu.sequence = 7;
      break;
    case MODULE_ACCESS_NAMES.USER_LIST:
      menu.link = '/supplier-user';
      menu.sequence = 8;
      break;
    case MODULE_ACCESS_NAMES.CONTRACT_PRICE:
      menu.link = '/contract-price/supplier-contract-list';
      menu.sequence = 9;
      break;
  }
}

export const downloadAndSaveFile = (fileUrl: string, downloadedFileName: string): void => {
  const downloadLink = document.createElement('a');
  downloadLink.style.display = 'none';
  downloadLink.href = fileUrl;
  downloadLink.setAttribute('download', downloadedFileName);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

import { User } from 'app/interfaces/users';
import { UserService } from 'app/core/services/user-service/user.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { SupplierOnboardService } from './core/services/supplier-onboard-service/supplier-onboard.service';
import { SupplierFormResponse } from './interfaces/supplier-onboarding/supplier-form-list.interface';
import { UtilService } from './core/services/util.service';

const MINUTES_UNITL_AUTO_LOGOUT = 30; // in mins
const CHECK_INTERVAL = 10000; // in ms
const STORE_KEY = 'lastAction';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    timer: any;
    title = 'eproc-supplier';

    // isLoading: boolean = false
    /**
     * Constructor
     */
    constructor(
        private router: Router,
        private _userService: UserService,
        private utils: UtilService
    ) {
        router.events
            .pipe(filter(e => e instanceof NavigationEnd))
            .subscribe((navEnd: NavigationEnd) => {

                const url = this.router.routerState.snapshot.url;
                const supplierContract = url.includes('contract-price');
                const invoice = url.includes('invoice');

                if (!supplierContract) {
                    localStorage.removeItem('contractPriceSort');
                    localStorage.removeItem('contractPriceFilter');
                }
                
                if (!invoice) {
                    localStorage.removeItem('invoicePageSize');
                    localStorage.removeItem('invoiceListFilter');
                }
            });
    }

    ngOnInit(): void {
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.start();

                localStorage.removeItem('Reset Timer');
                localStorage.removeItem('Logout Time');

                this.reset();
                this.check();
                this.initListener();
                this.initInterval();

                localStorage.setItem(STORE_KEY, Date.now().toString());
            });

        

        // transfers sessionStorage from one tab to another
        var sessionStorage_transfer = function (event) {
            // if(localStorage.getItem("auto-logout")){ return; }
            if (!event) {
                event = window.event;
            } // ie suq
            if (!event.newValue) return; // do nothing if no value to work with
            if (event.key == 'getSessionStorage') {
                // another tab asked for the sessionStorage -> send it
                localStorage.setItem(
                    'sessionStorage',
                    JSON.stringify(sessionStorage)
                );
                // the other tab should now have it, so we're done with it.
                localStorage.removeItem('sessionStorage'); // <- could do short timeout as well.
            } else if (
                event.key == 'sessionStorage' &&
                !sessionStorage.length
            ) {
                // another tab sent data <- get it
                var data = JSON.parse(event.newValue);
                for (var key in data) {
                    sessionStorage.setItem(key, data[key]);
                }
            }
        };

        // listen for changes to localStorage
        if (window.addEventListener) {
            window.addEventListener('storage', sessionStorage_transfer, false);
        }

        // Ask other tabs for session storage (this is ONLY to trigger event)
        if (!sessionStorage.length) {
            localStorage.setItem('getSessionStorage', 'foobar');
            localStorage.removeItem('getSessionStorage');
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.stop();
    }

    /** This code is to, auto logout the user, after 30 min of idle time. - start */
    public getLastAction() {
        return parseInt(localStorage.getItem(STORE_KEY));
    }

    public setLastAction(lastAction: number) {
        localStorage.setItem(STORE_KEY, lastAction.toString());
    }

    initListener() {
        window.addEventListener('click', () => this.reset());
        window.addEventListener('mouseover', () => this.reset());
        window.addEventListener('mouseout', () => this.reset());
        window.addEventListener('keydown', () => this.reset());
        window.addEventListener('keyup', () => this.reset());
        window.addEventListener('keypress', () => this.reset());
    }

    reset() {
        if (!sessionStorage.getItem('accessToken')) {
            window.removeEventListener('click', () => this.reset(), true);
            window.removeEventListener('mouseover', () => this.reset(), true);
            window.removeEventListener('mouseout', () => this.reset(), true);
            window.removeEventListener('keydown', () => this.reset(), true);
            window.removeEventListener('keyup', () => this.reset(), true);
            window.removeEventListener('keypress', () => this.reset(), true);
            return;
        }
        this.timer = formatDate(new Date(), 'hh:mm:ss a', 'en-US', '+0530');
        // localStorage.setItem("Reset Timer", this.timer);
        this.setLastAction(Date.now());
    }

    initInterval() {
        const intervalLogout = setInterval(() => {
            if (sessionStorage.getItem('accessToken')) {
                this.check();
            } else {
                clearInterval(intervalLogout);
            }
        }, CHECK_INTERVAL);
    }

    check() {
        const now = Date.now();
        const timeleft =
            this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
        const diff = timeleft - now;
        const isTimeout = diff < 0;

        if (isTimeout) {
            localStorage.setItem('auto-logout', 'true');
            this.logOut();
        }
    }

    // Bind the eventListener
    private start(): void {
        window.addEventListener(
            'storage',
            this.storageEventListener.bind(this)
        );
    }

    // Logout only when key is 'logout-event'
    private storageEventListener(event: StorageEvent) {
        if (event.storageArea == localStorage) {
            if (event.key == 'auto-logout') {
                this.logOut();
            }
        }
    }

    // Handle active listeners when onDestroy
    private stop(): void {
        window.removeEventListener(
            'storage',
            this.storageEventListener.bind(this)
        );
        localStorage.removeItem('lastAction');
    }

    public logOut = () => {
        sessionStorage.clear();
        localStorage.clear();

        // localStorage.setItem("Reset Timer", this.timer);
        // localStorage.setItem("Logout Time", formatDate(new Date(), 'hh:mm:ss a', 'en-US', '+0530'));

        this.router.navigate(['sign-in']);
    };
}

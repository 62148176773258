import { GlobalVariable } from "app/config";

export const EVENT_ENDPOINT = {};

export const GRA_ENDPOINT = {};

export const INVOICE_ENDPOINT = {};

export const DB_TAB_ENDPOINT = {
  COUNT: '/api/buyer/supplier-dashboard/dashboard-count',
  EVENT: '/api/buyer/supplier-dashboard/supplier-dashboard-events',
  EVENT_COUNT: '/api/buyer/supplier-dashboard/event-status-count',

  PURCHASE_ORDER: '/api/buyer/supplier-dashboard/po-list-for-supplier',
  GRA: '/api/buyer/supplier-dashboard/gra-list',
  INVOICE: '/api/buyer/supplier-dashboard/supplier-Invoice-list',
  MEETING_SCHEDULE:
    '/api/buyer/supplier-dashboard/supplier-dashboard-meeting-schedule',
  TASK_COUNT: '/api/buyer/supplier-dashboard/supplier-message-count',
};

export const SUPPLIER_ONBOARDING = {
  TERMS_N_CONDITIONS: '/api/buyer/supplier/get-tnc',
  ACCEPT_TERMS_N_CONDITIONS: '/api/buyer/supplier/accept-tnc',
};

export const ANNOUNCEMENT = {
  LIST: '/api/buyer/announcementsupplier/supplier-announcement-list',
  DETAILS: '/api/buyer/announcementsupplier/getAnnouncementSupplierById',
  PDF: `${GlobalVariable.BASE_URL}/api/buyer/announcementsupplier/supplier/announcement-supplier-pdf`,
};

import { MODULE_ACCESS_CHILD_NAMES, MODULE_ACCESS_NAMES, RFX_FORM_STATUS } from './../../../enums/common-enums/common-enum';
import { MatMenuTrigger } from '@angular/material/menu';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, Input, ViewChild, EventEmitter, Output, OnDestroy } from '@angular/core';
import { RFX_SUPPLIER_STATUS } from 'app/enums/rfx-enums/rfx.enum';
import { MatTableDataSource } from '@angular/material/table';
import { SupplierService } from 'app/core/services/supplier-service/supplier.service';
import { SupplierEvent } from 'app/interfaces/supplier-interface/supplier-event';
import { formatDateTime, hasAccess, parseUTCDateToLocal } from 'app/helper/shared-function';
import { MatSort, Sort } from '@angular/material/sort';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, lastValueFrom, Observable, ReplaySubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DashboardService } from 'app/core/services/dashboard-service/dashboard.service';

@Component({
  selector: 'app-event-table',
  templateUrl: './event-table.component.html',
  styleUrls: ['./event-table.component.scss']
})
export class EventTableComponent implements OnInit, OnDestroy {

  @Input() status: RFX_SUPPLIER_STATUS;
  @Output() counterEmitter: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatSort) sort!: MatSort;

  eventColumns: string[] = ['internalReferenceNumber', 'title', 'rfxSequenceId', 'eventStartDate', 'buyer', 'purchasingOrg'];
  eventList: Array<any> = [];
  filterEventList: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  columnSearchForm: FormGroup;

  rfxStatusEnum = RFX_FORM_STATUS;

  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource = new MatTableDataSource<any>();
  pageSizeOptions: number[] = [10, 25, 50, 100, 500];
  pageSize = 10;
  currentPage = 0;
  sortBy = 'eventStartDate';
  sortOrder = 'DESC';

  rfxDashboardSubs$: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private supplierService: SupplierService,
    private dashboardService: DashboardService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.initializeForm();
    this.getEventList();
  }

  ngOnDestroy(): void {
    this.rfxDashboardSubs$.forEach((subs: Subscription) => {
      subs.unsubscribe();
    });
    this.filterEventList.unsubscribe();
    this.counterEmitter ?? this.counterEmitter.complete();
  }

  initializeForm(): void {
    this.columnSearchForm = this.formBuilder.group({
      keyword: '',
      internalReferenceNumber: null,
      title: null,
      rfxSequenceId: null,
      buyer: null,
      purchasingOrg: null
    });
    this.bindFormEvent(Object.keys(this.columnSearchForm.controls));
  }

  bindFormEvent(keys: string[]): void {
    keys.forEach((key) => {
      const control = this.columnSearchForm.get(key);
      const search = control.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged(),)
        .subscribe((value) => {
          if(key === 'keyword'){
            this.getEventList();
          }else{
            this.filterEventTable();
          }
        });
      this.rfxDashboardSubs$.push(search);
    });
  }

  getEventList(): void {
    let columnFilters: any = {
      status: this.status,
      page: this.currentPage + 1,
      size: this.pageSize,
      keyword: this.columnSearchForm.value.keyword
    };

    if(this.sortBy){
      columnFilters.sortBy = this.sortBy;
    }
    if(this.sortOrder){
      columnFilters.sortOrder = this.sortOrder;
    }

    if (this.dashboardService.selectedCompany && this.dashboardService.selectedCompany.code) {
      columnFilters.purchasingOrgCode = this.dashboardService.selectedCompany.code;
    }

    // const listSubs = this.supplierService.getSupplierSourcingList(columnFilters).subscribe({
    //   next: (response) => {
    //     this.buildTableData(response.data);
    //     setTimeout(() => {
    //       this.paginator.pageIndex = this.currentPage;
    //       this.paginator.length = response.total;
    //     });
    //   },
    //   error: (err) => {
    //     console.error(err);
    //   }
    // });

    const listSubs = this.supplierService.getSupplierEvents(columnFilters).subscribe((response) => {
      this.buildTableData(response.result);
      setTimeout(() => {
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = response.total;
      });
    }, (error) => {
      console.error(error);
    });
    // this.rfxDashboardSubs$.push(listSubs);
  }

  buildTableData(response: SupplierEvent[]): void {
    if (response) {
      this.eventList = response.map(list => ({
        ['id']: list.id,
        ['rfxSequenceId']: list.rfxSequenceId,
        ['internalReferenceNumber']: list.internalReferenceNumber,
        ['title']: list.title,
        ['eventStartDate']: list.eventStartDate ? formatDateTime(list.eventStartDate) : null,
        ['buyer']: list.creator.name,
        ['purchasingOrg']: list.purchOrg?.name,
        ['status']: list.supplier?.selectedSupplier[0].status,
        ['rfxStatus']: list.status,
        ['isClosed']: this.isEventClosed(list.closingDate)
      }));
      this.counterEmitter.emit(this.eventList.length)
      this.filterEventList.next(this.eventList.slice());
    } else {
      this.eventList = [];
    }
  }

  isEventClosed(closingDate: string): boolean {
    const closeDate = parseUTCDateToLocal(closingDate);
    const currentDate = new Date();
    return closeDate.getTime() < currentDate.getTime();
  }

  filterEventTable(): void {
    if (!this.eventList || this.eventList?.length === 0) {
      return;
    }
    const colFilter = this.getColumnFormFilter();
    const keys: string[] = Object.keys(colFilter);
    if (keys.length > 0) {
      let tempList = Object.assign([], this.eventList);
      keys.forEach((searchKey) => {
        tempList = tempList.filter((item) => {
          return item[searchKey] ? item[searchKey].toString().toLowerCase().indexOf(colFilter[searchKey].toLowerCase()) > -1 : true;
        }
        );
      });
      this.filterEventList.next(tempList);
    } else {
      this.filterEventList.next(this.eventList.slice());
    }
  }

  getColumnFormFilter(): any {
    const searchFormValue = Object.assign({}, this.columnSearchForm.value);
    Object.keys(searchFormValue).map((key) => {
      if (searchFormValue[key] == null || searchFormValue[key] === '') {
        delete searchFormValue[key];
      }
    });
    return searchFormValue;
  }

  isEventEnded(rfxStatus: string): boolean {
      return rfxStatus === this.rfxStatusEnum.PENDING_BUYER_EVALUATION
          || rfxStatus === this.rfxStatusEnum.PENDING_REQUESTOR_EVALUATION
          || rfxStatus === this.rfxStatusEnum.PENDING_EVALUATION_APPROVAL
          || rfxStatus === this.rfxStatusEnum.COMPLETE
          || rfxStatus === this.rfxStatusEnum.SOURCING_PROPOSAL_DRAFT
          || rfxStatus === this.rfxStatusEnum.SOURCING_PROPOSAL_PENDING
          || rfxStatus === this.rfxStatusEnum.FINISHED;
  }


  onRowSelect(row: any): void {
    let redirectUrl = '';
    const hasAccessRight: boolean = hasAccess(MODULE_ACCESS_NAMES.EVENTS, MODULE_ACCESS_CHILD_NAMES.NORMAL);
    if(this.isEventEnded(row.rfxStatus)) {
      if (row.status === RFX_SUPPLIER_STATUS.INVITED || row.status === RFX_SUPPLIER_STATUS.REJECTED) {
        redirectUrl = '/sourcing/closed';
      }
      if (row.status === RFX_SUPPLIER_STATUS.ACCEPTED || row.status === RFX_SUPPLIER_STATUS.SUBMITTED) {
        redirectUrl = '/sourcing/detail';
      }
    } else {
      if (row.status === RFX_SUPPLIER_STATUS.INVITED || row.status === RFX_SUPPLIER_STATUS.PREVIEWED) {
        redirectUrl = '/sourcing/invite';
      }
      if (row.status === RFX_SUPPLIER_STATUS.ACCEPTED) {
        redirectUrl = hasAccessRight ? '/sourcing/create' : '/sourcing/summary';
      }
      if (row.status === RFX_SUPPLIER_STATUS.SUBMITTED ) {
        redirectUrl = '/sourcing/detail';
      }
      if (row.status === RFX_SUPPLIER_STATUS.REJECTED) {
        redirectUrl = '/sourcing/reject';
      }
    }

    if(redirectUrl !== ''){
      const params = { id: row.id };
      if(row.isNewTab){
        const url = this.router.serializeUrl(
          this.router.createUrlTree([redirectUrl], {
            queryParams: params,
          })
        );
        const newTab = window.open(url, '_blank');
        if (newTab) {
          newTab.opener = null;
        }
        row.isNewTab = false;
      }else{
        this.router.navigate([redirectUrl], { queryParams: params });
      }
    }
  }

  pageChanged(event: PageEvent): void {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getEventList();
  }

  searchFilter() {
    this.getEventList();
  }

  //Note: Sorting via global way using API's
  sortData(sort: Sort) {
    let direction = sort.direction?.toUpperCase();
    let column = sort.active;

    if (!column || direction === '') {
      column = '';
      direction = '';
    }

    this.sortBy = column,
    this.sortOrder = direction

    this.getEventList();
  }

  onContextMenu(event: MouseEvent, item: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { item: item };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  onContextMenuAction(item: any) {
    item.isNewTab = true;
    this.onRowSelect(item);
  }

}

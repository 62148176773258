<div class="input-field-container">
    <mat-form-field class="fuse-mat-dense w-full" floatLabel="always" appearance="fill">
        <mat-label [ngClass]="{'danger' : (control?.touched || control?.dirty) && control?.errors}" *ngIf="fieldLabel">
            {{ fieldLabel }}
        </mat-label>
        <input matInput [readonly]="readonly" [type]="fieldType" [placeholder]="placeholder" (input)="inputHandler($event.target.value)"
            [required]="isFieldRequird" [disabled]="isFieldDisabled" [errorStateMatcher]="stateMatcher"
            [ngModel]="initialValue" (blur)="onTouched()" [maxlength]="maxLength"/>
        <mat-hint *ngIf="hint">{{hint}}</mat-hint>
    </mat-form-field>
    <app-error-message *ngIf="control?.control?.errors" [control]="control?.control" ></app-error-message>
</div>

export enum STATUS {
  ACTIVE = 'active',
  IN_ACTIVE = 'in-active'
}

export enum EVENT_TYPE {
  RFQ = 'RFQ',
  RFT = 'RFT',
  RFA = 'RFA',
  RFP = 'RFP',
  RFI = 'RFI'
}

export enum RFX_FORM_STATUS {
  DRAFT = 'Draft',
  PENDING = 'Pending',
  ACTIVE = 'Active',
  // APPROVED = 'approved',
  // REJECTED = 'rejected',
  CANCELLED = 'cancelled',
  FINISHED = 'Finished',
  // ESCALATE = 'escalated',
  // INACTIVE = 'inactive',
  // SUSPENDED = 'Suspended',

  // new statuses for rfx/sourcing.
  PENDING_BUYER_EVALUATION = 'Pending Buyer Evaluation', // submit
  PENDING_REQUESTOR_EVALUATION = 'Pending Requestor Evaluation',
  PENDING_EVALUATION_APPROVAL = 'Pending Evaluation Approval',
  COMPLETE = 'Completed',
  SOURCING_PROPOSAL_DRAFT = 'Sourcing Proposal Draft',
  SOURCING_PROPOSAL_PENDING = 'Sourcing Proposal Pending',
  // CLOSED = 'Closed'
}

export enum RFX_APPROVAL_LEVEL_STATUS {
  DRAFT = 'draft',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PRE_PR_REJECTED = 'pre pr rejected' /// need to add in BE
}


export enum RFX_APPROVAL_ACTION {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PRE_PR_REJECTED = 'pre pr rejected'
}

export enum RFX_ROUTE_ACTION {
  RFX_TEMPLATE_METADATA = 'APPLY RFX TEMPLATE METADATA',
  PRE_PR_AND_RFX_METADATA = 'APPLY PRE PR AND RFX METADATA',
  EDIT_RFX_FORM = 'EDIT RFX FORM'
}


export enum EQUIVALENT_BRAND_ALLOWED {
  YES = 'Yes',
  YES_WITH_OPTION = 'Yes with option',
  NO = 'No',
  NOT_APPLICABLE = 'Not applicable'
}

export enum PO_FILTER_STATUS {
  ACCEPTED = 'accepted',
  DECLINE = 'declined',
  FULFILLED = 'fulfilled',
  NEW = 'new',
  PARTIAL = 'partial',
}

export enum GRA_STATUS {
  BILLED = 'billed',
  UNBILLED = 'unbilled'
}

export enum ADDRESS_TYPE {
  MANUAL = 'MANUAL',
  LIST = 'LIST',
}

export enum MODULE_ACCESS_NAMES {
  EVENTS = 'Events',
  GOOD_RECEIPT_ADVICE = 'Good Receipt Advice',
  INVOICE = 'Invoice',
  PURCHASE_ORDER = 'Purchase Order',
  PAYMENT_REMITTANCE = 'Payment Remittance',
  CONTRACT_PRICE = 'Contract Price',
  SUPPLIER_FORM = 'Supplier Form',
  USER_LIST = 'User List',
  SUPPLIER_PROFILE = 'Supplier Profile',
  ANNOUNCEMENT = 'Announcement',
}

export enum MODULE_ACCESS_CHILD_NAMES {
  NORMAL = 'Normal',
  VIEWER = 'Viewer',
}

export enum InvoiceTypeEnum {
  PO = 'PO',
  GRA = 'GRA',
}


export enum RfxEventType {
  QUOTATION = 'request for quotation',
  TENDER = 'request for tender',
}

export enum RfxTemplateStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum ViewerType {
  VIEWER = 'viewer',
  ASSOCIATE_VIEWER = 'associate viewer',
}

export enum APPROVAL_ROUTE_TYPE {
  APPROVAL_ROUTING = 'approval-routing',
  SOURCING_PROPOSAL_ROUTING = 'sourcing-proposal-routing',
}

export enum AttachmentType {
  BILL_OF_QUANTITY = 'bill of quantity',
  QUESTIONNAIRE = 'questionnaire',
}

export enum ANSWER_TYPE {
  CHOICE = 'choice', // radio - only one.
  CHOICE_WITH_SCORE = 'choice with score', //radio - only one.
  TEXT = 'text',
  CHECKBOX = 'checkbox', // checkbox - multiple.
  DATE = 'date', // date picker.
  NUMBER = 'number', // number.
  FILE_ATTACHMENT = 'File Attachment '
}

export enum DOCUMENT_TYPE {
  EXTERNAL = 'External',
  INTERNAL = 'Internal',
}

export enum RFX_BASED_ON {
  PRE_PR = 'pre pr',
  RFX_TEMPLATE = 'rfx template',
}

export enum RFX_SUPPLIER_STATUS {
  INVITED = 'INVITED',
  PREVIEWED = 'PREVIEWED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
  ENDED = 'ENDED',
  PENDINGSUBMISSION = 'PENDING SUBMISSION',
  // QUALIFIED = 'QUALIFIED',
  // DISQUALIFIED = 'DISQUALIFIED',
}

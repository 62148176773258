import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import moment from 'moment';
// import { AuthService } from '../auth/auth.service';
import { DateTimeUtilService } from './date-time-util.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private dateTimeUtil: DateTimeUtilService
  ) { }

  downloadExcelByUrl(url: string, fileName: string): void {
    const offset = moment().utcOffset();
    const urlArry = url.split('?');
    if (typeof urlArry[1] === 'undefined') {
      this.downloadExcel(`${url}?offset=${offset}`, fileName, null);
    } else {
      this.downloadExcel(`${url}&offset=${offset}`, fileName, null);
    }
  }

  downloadExcel(url: string, fileName: string, body: any): void {
    this.callDownloadAPI(
      url,
      fileName,
      body,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  }

  downloadPdf(url: string, fileName: string, body: any): void {
    this.callDownloadAPI(url, fileName, body, 'application/pdf');
  }

  callDownloadAPI(
    url: string,
    fileName: string,
    body: any,
    type?: string
  ): void {
    const headers = new HttpHeaders().set(
      'authorization',
      'Bearer ' + this.authService.accessToken
    );

    this.http
      .post(url, body, {
        headers,
        responseType: 'blob' as 'text',
      })
      .subscribe((response: any) => {
        const binaryData = [response];
        const downloadLink = document.createElement('a');
        downloadLink.style.display = 'none';
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, {
            type: type,
          })
        );
        fileName += `${this.dateTimeUtil.getDateForFileName(new Date())}`;
        downloadLink.setAttribute('download', fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        // hideLoader;
      });
  }

  callDownloadGetAPI(
    url: string,
    fileName: string,
    type?: string,
    eventEmitter?: EventEmitter<any>,
    onlyFileName?: string
  ): void {
    const headers = new HttpHeaders().set(
      'authorization',
      'Bearer ' + this.authService.accessToken
    );
    this.http
      .get(url, {
        headers,
        responseType: 'blob' as 'text',
      })
      .subscribe(
        (data) => {
          let blob = new Blob([data], { type: type });

          const downloadLink = document.createElement('a');
          downloadLink.style.display = 'none';
          downloadLink.href = window.URL.createObjectURL(blob);
          if (onlyFileName) {
            fileName = onlyFileName;
          } else {
            fileName += ` - ${this.dateTimeUtil.getDateForFileName(
              new Date()
            )}`;
          }
          downloadLink.setAttribute('download', fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          if (eventEmitter) {
            eventEmitter.emit();
          }
        },
        (error) => {
          console.log(error.error.message);
          if (eventEmitter) {
            eventEmitter.emit();
          }
        }
      );
  }

  public saveAsFile(data: HttpResponse<Blob>, fileNamePrefix: string, toShowDateTime?: boolean) {
    const contentDisposition = data.headers.get('content-disposition');
    const filename = this.getFilenameFromContentDisposition(
      contentDisposition ?? '',
      fileNamePrefix,
      toShowDateTime ?? true
    );
    const blob = data.body;
    const url = window.URL.createObjectURL(blob ?? new Blob());
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = url;
    anchor.click();
  }
  public saveAsGRAFile(data: HttpResponse<Blob>, fileNamePrefix: string): void {
    const contentDisposition = data.headers.get('content-disposition');
    const filename = fileNamePrefix;
    const blob = data.body;
    const url = window.URL.createObjectURL(blob ?? new Blob());
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = url;
    anchor.click();
  }

  private getFilenameFromContentDisposition(
    contentDisposition: string,
    fileNamePrefix: string,
    toShowDateTime?: boolean,
  ): string {
    const regex = /filename=(?<filename>[^,;]+);/g;
    const match = regex.exec(contentDisposition);
    if (match != null && match.groups != null) {
      const filename = match.groups['filename'];
      return filename;
    } else {
      if (toShowDateTime) {
        return `${fileNamePrefix} - ${this.dateTimeUtil.convertDate(new Date(), 'YYYY/MM/dd HH:mm:ss a')}`;
      } else {
        return `${fileNamePrefix}`;
      }
    }
  }
}

import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { Component, Input, Output, OnChanges, SimpleChanges, EventEmitter, Self, Optional, AfterViewInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { DROPDOWN_TYPE } from 'app/enums/form-enum/form-enum';
import { CustomFieldErrorMatcher } from 'app/helper/custom-validators';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * In order to use this dropdown please follow these steps --
 *
 * By default dropdown type is simple i.e without search,
 * if you want to use search feature than pass **[dropdownType]="DROPDOWN_TYPE.SEARCH"** in selector
 *
 * This dropdown accepts ARRAY OF OBJECTS in each object pass  **name** key like this -->
 *
 * **{ name : 'value you want to display in dropdown' }** ,
 *
 * If you don't pass the **name** key in the object than it is mandatory to pass [optionViewKey] in the selector
 * and the value of **[optionViewKey]** will your one of the object value to whom you want to optionViewKey in the dropdown,
 * and **search query will be based on [optionViewKey]**.
 *
 * If you want your selected value in different format then pass **[outputPattern]="['value', 'id']"
 * example when you selected **hail hydra** in your dropdown, this will result like { value : 'hail hydra', id : '' }.
 * Most important **outputPattern** value should be available in **options**
 *
 *
 * control value accessor interface is implemented on this dropdown so you can use this formGroup, to use that pass **formControlName="controlName"**
 *
 */
@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements ControlValueAccessor, OnChanges, AfterViewInit {
    @Input() fieldLabel: string
    @Input() readonly: boolean = false
    @Input() placeholder: string
    @Input() options: object[] = []
    @Input() dropdownType: DROPDOWN_TYPE = DROPDOWN_TYPE.SIMPLE
    @Input() outputPattern: any[] = []
    @Input() optionViewKey: string = 'name'
    @Input() useUppercase: boolean = false;
    @Input() useDefaultCasing: boolean;

    @Output() changeEvent = new EventEmitter<object | string>()

    stateMatcher: ErrorStateMatcher
    DROPDOWN_TYPE = DROPDOWN_TYPE

    initialValue: string
    isFieldRequired: boolean
    isFieldDisabled: boolean = false
    addMoreDo: boolean = false;

    filteredOptions: any[] = []
    multiselectValue: string[] = [] // sroing value if

    constructor(@Self() @Optional() public control: NgControl) {
        this.control && (this.control.valueAccessor = this);
    }

    updateForm = (value: any) => { }
    onTouched = () => { };

    ngOnChanges(): void {
        this.filteredOptions = this.options;
        this.filteredOptions?.map((ele) => ele?.sapGraNumber && ele?.doNumber ? this.addMoreDo = true: false);
    }

    writeValue(defaultValue: any): void {
        this.initialValue = defaultValue
        if (!defaultValue)  return

        this.onTouched()
        const interval = setInterval(() => {
            if (this.options && this.options.length) {
                this.options.forEach((element: any) => {
                    const optionValues = Object.values(element)
                    optionValues.includes(defaultValue) ? this.initialValue = element[this.optionViewKey] : null

                    // in some cases type of default value is object..
                    if (typeof defaultValue === 'object') {
                        const defaultValueKey = Object.keys(defaultValue)
                        defaultValueKey.includes(this.optionViewKey) ? this.initialValue = defaultValue[this.optionViewKey] : null
                    }
                });
                clearInterval(interval)
            }
        }, 500)
    }

    registerOnChange(fn: any): void { this.updateForm = fn }
    registerOnTouched(fn: any): void { this.onTouched = fn }
    setDisabledState(isDisabled: boolean): void { this.isFieldDisabled = isDisabled }

    searchHandler(searchKey: string) {
        this.filteredOptions = this.options.filter((el: any) => {
            if (el[this.optionViewKey].toLowerCase().includes(searchKey.toLowerCase())) {
                return el
            }
        })
    }

    supplyFinalResult(processedResult: any) {
        this.updateForm(processedResult)
        this.changeEvent.next(processedResult)
        // console.log("processed result -- ", processedResult);
    }

    generateDpResult(e: MatSelectChange) {
        const [selected] = this.options.filter((el: any) => el[this.optionViewKey] === e.value)
        this.onTouched()

        if (!this.outputPattern.length) {
            this.supplyFinalResult(e.value)
            this.updateForm(e.value)
            return
        }

        // this will result in object if you pass outputPattern in selector
        const result: any = {}
        const patternList = [...this.outputPattern]
        const modifiedPattern = typeof patternList[1] === 'object' ? patternList.slice(0, 1) : patternList

        modifiedPattern.forEach((pattern: string) => {
            selected[pattern] && (result[pattern] = selected[pattern])
        })

        if (typeof patternList[1] === 'object') {
            this.supplyFinalResult(result)
            return
        }

        if (modifiedPattern.length === 1) this.supplyFinalResult(result[modifiedPattern[0]])
        else this.supplyFinalResult(result)
    }

    ngAfterViewInit(): void {
        const timer = setTimeout(() => {
            if (this.control) {
                this.stateMatcher = new CustomFieldErrorMatcher(this.control.control);
                this.isFieldRequired = this.control?.control?.hasValidator(Validators.required)
                clearTimeout(timer)
            }
        }, 100)
    }
}

import { AfterViewInit, Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { CustomFieldErrorMatcher } from 'app/helper/custom-validators';

@Component({
    selector: 'app-time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() fieldLabel: string

    defaultTime: string
    isFieldRequird: boolean
    stateMatcher: ErrorStateMatcher

    onTouched = () => { };
    updateMainForm = (selectedValue: string) => { }

    /* 
     we are acquiring the FormControl of time picker component to get the validation status and display the error messages inside the component itself.

     When we try to acquire NgControl through DI Angular throws a circular dependency error,
     and to avoid instead of providing our component through NG_VALUE_ACCESSOR we need to set the valueAccessor in the constructor.
    */
    constructor(@Self() @Optional() public control: NgControl) {
        this.control && (this.control.valueAccessor = this);
    }

    ngOnInit(): void { }

    timeEvent(event) {
        this.updateMainForm(event)
    }

    writeValue(defaultTime: string): void {
        this.defaultTime = defaultTime
    }
    registerOnChange(fn: any): void { this.updateMainForm = fn }
    registerOnTouched(fn: any): void { this.onTouched = fn }

    ngAfterViewInit(): void {
        const timeId = setTimeout(() => {
            if (this.control) {
                this.stateMatcher = new CustomFieldErrorMatcher(this.control.control);
                this.isFieldRequird = this.control?.control?.hasValidator(Validators.required)
                clearTimeout(timeId)
            }
        })
    }

}

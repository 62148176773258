export enum SupplierSupportingDocType {
  COMPANY_FORM = 'companyForm',
  COMPANY_PROFILE = 'companyProfile',
  COMPANY_PHOTOS = 'companyPhoto',
  OTHER = 'other',
  COMPANY_LETTER_HEAD = 'companyLetterHead',
  INTERNAL = 'internal',

}

export enum SupplierProfileDocType {
  COMPANY_FORM = 'companyForm',
  COMPANY_PROFILE = 'companyProfile',
  COMPANY_PHOTOS = 'companyPhoto',
  OTHER = 'other',
  FINANCIAL = 'financial',
}

export enum CompanyType {
  BERHAD = 'Berhad (Bhd)',
  SENDIRIAN_BERHAD = 'Sendirian Berhad (Sdn Bhd)',
  PARTNERSHIP = 'Partnership (LLP)',
  ENTERPRISE = 'Enterprise (Sole Proprietary)',
  OTHERS = 'Others',
  NA = 'Not Applicable',
}

export enum BumiputraStatus {
  BUMIPUTRA = 'Bumiputra',

  NON_BUMIPUTRA = 'Non-Bumiputra',
}

export enum SupplierProfileStatus {
  TEMPORARY = 'Temporary',
  PENDING_APPROVAL = 'Pending Approval',
  PENDING_SAP = 'Pending SAP',
  REJECTED = 'Rejected',
  ACTIVE = 'Active',
  SUSPENDED = 'Suspended',
  BLACKLIST = 'Blacklist',
  INACTIVE = 'Inactive',
  LOCKED = 'Locked'
}

export enum SupplierEvaluationStatus {
  TEMPORARY = 'Good',

  PENDING_APPROVAL = 'Average',

  REJECTED = 'Poor',
}

export class SupplierSupportingDocument {
  id: number;
  filename: string;
  originalname: string;
  description: string;
  docType: SupplierSupportingDocType;
  path: string;
  vendorCode?: string;
  isEditable: boolean;
}

export class SupplierFinancialDocument {
  id: number;

  filename: string;

  originalname: string;

  description: string;

  path: string;

  vendorCode?: string;
}


import { hasUserLogin, curentUserInfo } from 'app/helper/shared-function';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { catchError, finalize, map, Observable, throwError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { NavigationExtras, Router } from '@angular/router';
import { LoaderService } from '../services/common/loader.service';

// url to prevent loader
const urls = ['api/buyer/supplier-form-response/submit'];
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private loader: LoaderService
  ) {}

  /**
   * Intercept
   *
   * @param req
   * @param next
   */

  isValidUrl(urlString: string) {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  }

  getURLPath = (url: string) => {
    if (this.isValidUrl(url)) {
      const tempURL = new URL(url);
      return tempURL.pathname.toLowerCase().replace(/^\/|\/$/gm, '');
    }
  };

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (hasUserLogin()) {
      const LoggedInUser = curentUserInfo();
      if (LoggedInUser && LoggedInUser?.defaultPassword === true) {
        this._router.navigate(['/dashboard/reset-password'], {
          queryParams: { defaultPassword: 'yes' },
        });
      }
    }

    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (this._authService.accessToken) {
      newReq = req.clone({
        headers: req.headers.set(
          'Authorization',
          'Bearer ' + this._authService.accessToken
        ),
      });
    }

    // if ( this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken) )
    // {
    //     newReq = req.clone({
    //         headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken)
    //     });
    // }

    if (this._authService.showLoader) {
      const url = this.getURLPath(req.url);
      if (!urls.includes(url)) {
        this.loader.setLoading(true, req.url);
      }
    }

    // Response
    return next.handle(newReq).pipe(
      catchError((error) => {
        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // Sign out
          const currentUrl = this._router.url;
          this._authService.signOut().subscribe(() => {
            const navigationExtras: NavigationExtras = { queryParams: { returnUrl: currentUrl } };
            this._router.navigate(['sign-in'], navigationExtras);
        })
        
          // // Reload the app
          // location.reload();
        }

        return throwError(error);
      }),
      finalize(() => {
        this.loader.setLoading(false, req.url);
        this._authService.showLoader = true;
      })
    );
  }
}

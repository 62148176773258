import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl, Validators, FormControl } from '@angular/forms';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { DateAdapter, ErrorStateMatcher, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomFieldErrorMatcher } from 'app/helper/custom-validators';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment} from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class DatePickerComponent implements ControlValueAccessor, AfterViewInit {
    date = new FormControl(moment());
    @Input() fieldLabel: string
    @Input() disablePastDate:boolean = true

    @Output() changeEvent = new EventEmitter<Date>()

    defaultDate: Date
    todayDate: Date = new Date();

    isFieldRequird: boolean
    isFieldDisabled: boolean
    stateMatcher: ErrorStateMatcher

    onTouched = () => { };
    updateMainForm = (selectedValue: Date) => {
        this.changeEvent.next(selectedValue)
     }

    constructor(@Self() @Optional() public control: NgControl) {
        this.control && (this.control.valueAccessor = this);
    }

    dateEvent(event: Date) {
        this.updateMainForm(event)
    }

    writeValue(defaultDate: Date): void {
        this.defaultDate = defaultDate        
    }

    registerOnChange(fn: any): void { this.updateMainForm = fn }
    registerOnTouched(fn: any): void { this.onTouched = fn }
    setDisabledState(disabled: boolean) { this.isFieldDisabled = disabled }


    ngAfterViewInit(): void {
        const timeId = setTimeout(() => {
            if (this.control) {
                this.stateMatcher = new CustomFieldErrorMatcher(this.control.control);
                this.isFieldRequird = this.control?.control?.hasValidator(Validators.required)
                clearTimeout(timeId)
            }
        })
    }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { MODULE_ACCESS_CHILD_NAMES, MODULE_ACCESS_NAMES } from 'app/enums/common-enums/common-enum';
import { curentUserInfo, getMenu, hasAccess } from 'app/helper/shared-function';
import { Menu } from 'app/interfaces/common-interface/menu';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  /**
   * Constructor
   */
  loggedUser = curentUserInfo();
  menu: Menu[] = getMenu();

  constructor(
    private _authService: AuthService,
    private _router: Router
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(redirectUrl);
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(redirectUrl);
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this._check('/');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @param redirectURL
   * @private
   */
  private _check(redirectURL: string): Observable<boolean> {
    // Check the authentication status
    return this._authService.check()
      .pipe(
        switchMap((authenticated) => {

          // If the user is not authenticated...
          if (!authenticated) {
            // Redirect to the sign-in page
            this._router.navigate(['sign-in'], { queryParams: { redirectURL } });

            // Prevent the access
            return of(false);
          }


          if (this.loggedUser?.defaultPassword === true) {
            this._router.navigate(['/dashboard/reset-password'], { queryParams: { 'defaultPassword': 'yes' } });

            // Prevent the access
            return of(false);
          }

          // Check User Access Rights
          if(redirectURL !== '/' && !this.checkHasAccess(redirectURL)){
            this._router.navigate(['/404-not-found']);
            // Prevent the access
            return of(false);
          }

          // Allow the access
          return of(true);
        })
      );
  }

  private checkHasAccess(redirectURL: string): boolean {
    if (!this.menu || this.menu.length < 3) {
        this.menu = getMenu();
    }
    const index = this.menu.findIndex(item => {
        return item.link === redirectURL || redirectURL.includes(item.link)
    });
    let hasAccessRight = index >= 0;
    if (redirectURL.includes('/sourcing/create')) {
        hasAccessRight = hasAccess(MODULE_ACCESS_NAMES.EVENTS, MODULE_ACCESS_CHILD_NAMES.NORMAL);
    }

    return hasAccessRight;
  }
}
